import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from './config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';
import { Alert } from 'react-bootstrap';
import { Upload, Download, Trash2 } from 'lucide-react';
import './PublisherReports.css';
import { jwtDecode } from 'jwt-decode';

const PublisherReports = () => {
  const [publisherReports, setPublisherReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState([]);
  const [files, setFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [downloadingInvoices, setDownloadingInvoices] = useState([]);
  const [deletingInvoices, setDeletingInvoices] = useState([]);

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const token = localStorage.getItem('token');
  const userId = getUserIdFromToken(token);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            
            const [reportsResponse, invoicesResponse] = await Promise.all([
                axios.get(`${BASE_API_URL}api/linksreports/get-publisher-reports`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/get-user-invoices`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);

            const reports = reportsResponse.data || [];
            const invoices = invoicesResponse.data || [];

            // Combine reports with invoices based on date range
            const combinedReports = reports.map(report => {
                const matchingInvoices = invoices.filter(invoice => 
                    new Date(invoice.dateStart) >= new Date(report.dateStart) &&
                    new Date(invoice.dateEnd) <= new Date(report.dateEnd)
                );
                return { ...report, invoices: matchingInvoices };
            });

            setPublisherReports(combinedReports);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (userId) {
        fetchData();
    }
}, [userId]);

  const handleDownloadInvoice = async (invoiceId, fileName) => {
    try {
      setDownloadingInvoices(prev => [...prev, invoiceId]);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/download/${invoiceId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob'
      });

      // Check if the response is actually a blob and not an error message
      const contentType = response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        // If it's JSON, it's probably an error message
        const reader = new FileReader();
        reader.onload = () => {
          const errorMessage = JSON.parse(reader.result);
        };
        reader.readAsText(response.data);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Error downloading invoice:", error);
    } finally {
      setDownloadingInvoices(prev => prev.filter(id => id !== invoiceId));
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleFileUpload = async (e, index) => {
    e.preventDefault();

    if (!files[index]) {
        return;
    }

    // Format dates to start of day in local timezone
    const dateStart = new Date(publisherReports[index].dateStart);
    const dateEnd = new Date(publisherReports[index].dateEnd);
    
    // Set time to noon (12:00) to avoid timezone issues
    dateStart.setHours(12, 0, 0, 0);
    dateEnd.setHours(12, 0, 0, 0);

    const formData = new FormData();
    formData.append('invoiceFile', files[index]);
    formData.append('userId', userId.toString());
    formData.append('dateStart', dateStart.toISOString());
    formData.append('dateEnd', dateEnd.toISOString());

    const currentUploading = [...uploading];
    currentUploading[index] = true;
    setUploading(currentUploading);

    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${BASE_API_URL}api/PublisherInvoiceUpload/upload`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.data.success) {
            // Clear the file input
            setFiles(prevFiles => {
                const newFiles = [...prevFiles];
                newFiles[index] = null;
                return newFiles;
            });

            // Fetch updated data
            const [reportsResponse, invoicesResponse] = await Promise.all([
                axios.get(`${BASE_API_URL}api/linksreports/get-publisher-reports`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${BASE_API_URL}api/PublisherInvoiceUpload/get-user-invoices`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);

            const reports = reportsResponse.data || [];
            const invoices = invoicesResponse.data || [];

            // Combine reports with invoices
            const updatedReports = reports.map(report => {
                const matchingInvoices = invoices.filter(invoice => {
                    const invStart = new Date(invoice.dateStart);
                    const invEnd = new Date(invoice.dateEnd);
                    const repStart = new Date(report.dateStart);
                    const repEnd = new Date(report.dateEnd);

                    return invStart.toDateString() === repStart.toDateString() && 
                           invEnd.toDateString() === repEnd.toDateString();
                });
                return { ...report, invoices: matchingInvoices };
            });

            setPublisherReports(updatedReports);
        }
    } catch (error) {
        console.error('Error uploading invoice:', error);
    } finally {
        const newUploading = [...uploading];
        newUploading[index] = false;
        setUploading(newUploading);
    }
  };

  const handleDeleteInvoice = async (invoiceId) => {
    try {
      setDeletingInvoices(prev => [...prev, invoiceId]);
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${BASE_API_URL}api/PublisherInvoiceUpload/delete/${invoiceId}`, 
        {
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        const updatedReports = publisherReports.map(report => ({
          ...report,
          invoices: report.invoices.filter(invoice => invoice.id !== invoiceId)
        }));
        
        setPublisherReports(updatedReports);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    } finally {
      setDeletingInvoices(prev => prev.filter(id => id !== invoiceId));
    }
  };

  const confirmAndDeleteInvoice = (invoice) => {
    setInvoiceToDelete({
      id: invoice.id,
      fileName: invoice.fileName
    });
    setShowDeleteModal(true);
  };

  const DeleteConfirmationModal = ({ onClose, onConfirm }) => {
    return (
      <div className="delete-modal-overlay">
        <div className="delete-modal">
          <h2 className="delete-modal__title">Delete Invoice</h2>
          <p className="delete-modal__message">
            Are you sure you want to delete invoice: <br/>
            <span className="delete-modal__filename">{invoiceToDelete.fileName}</span>?
            <br/>This action cannot be undone.
          </p>
          <div className="delete-modal__buttons">
            <button className="delete-modal__cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button className="delete-modal__delete-btn" onClick={onConfirm}>
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="publisher-reports">
        <h5 className="publisher-reports__header">Publisher Reports</h5>
        <div className="container-fluid">
          {isLoading ? (
            <MySpinner />
          ) : (
            <>
              {/* Desktop Table Layout */}
              <div className="desktop-view">
                <div className="publisher-reports__table-responsive">
                  <table className="publisher-reports__table">
                    <thead className="publisher-reports__thead">
                      <tr>
                        <th className="publisher-reports__th">Start Date</th>
                        <th className="publisher-reports__th">End Date</th>
                        <th className="publisher-reports__th">Amount</th>
                        <th className="publisher-reports__th">Orders</th>
                        <th className="publisher-reports__th">Upload</th>
                        <th className="publisher-reports__th">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="publisher-reports__tbody">
                      {publisherReports.map((report, index) => (
                        <tr key={index}>
                          <td className="publisher-reports__td">
                            {new Date(report.dateStart).toLocaleDateString()}
                          </td>
                          <td className="publisher-reports__td">
                            {new Date(report.dateEnd).toLocaleDateString()}
                          </td>
                          <td className="publisher-reports__td">
                            ${report.amount.toFixed(2)}
                          </td>
                          <td className="publisher-reports__td">
                            {report.orders.length}
                          </td>
                          <td className="publisher-reports__td">
                            <form onSubmit={(e) => handleFileUpload(e, index)} className="publisher-reports__upload-form">
                              <div className="publisher-reports__file-input-wrapper small-input">
                                <input
                                  type="file"
                                  id={`fileInput-${index}`}
                                  className="publisher-reports__file-input"
                                  accept="application/pdf"
                                  onChange={(e) => handleFileChange(e, index)}
                                />
                                <label htmlFor={`fileInput-${index}`} className="publisher-reports__file-label small-label">
                                  <Upload size={12} />
                                  <span>Upload</span>
                                </label>
                              </div>
                              <button 
                                type="submit" 
                                className="publisher-reports__submit-btn small-btn" 
                                disabled={uploading[index] || !files[index]}
                              >
                                {uploading[index] ? (
                                  <MySpinner small />
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </form>
                          </td>
                          <td className="publisher-reports__td">
                            <div className="publisher-reports__invoice-actions">
                              {(report.invoices || []).map(invoice => (
                                <div key={invoice.id} className="publisher-reports__invoice-action-row">
                                  <button 
                                    className="publisher-reports__download-btn"
                                    onClick={() => handleDownloadInvoice(invoice.id, invoice.fileName)}
                                    disabled={downloadingInvoices.includes(invoice.id)}
                                  >
                                    {downloadingInvoices.includes(invoice.id) ? (
                                      <MySpinner small />
                                    ) : (
                                      <>
                                        <Download size={16} />
                                        <span>{invoice.fileName}</span>
                                      </>
                                    )}
                                  </button>
                                  <button 
                                    className="publisher-reports__delete-btn"
                                    onClick={() => confirmAndDeleteInvoice(invoice)}
                                    title="Delete Invoice"
                                    disabled={deletingInvoices.includes(invoice.id)}
                                  >
                                    {deletingInvoices.includes(invoice.id) ? (
                                      <MySpinner small />
                                    ) : (
                                    <Trash2 size={16} />
                                    )}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Mobile Card Layout */}
              <div className="mobile-view">
                <div className="publisher-reports__cards">
                  {publisherReports.map((report, index) => (
                    <div key={index} className="publisher-reports__card">
                      <div className="publisher-reports__card-header">
                        Report for {new Date(report.dateStart).toLocaleDateString()} - {new Date(report.dateEnd).toLocaleDateString()}
                      </div>
                      <div className="publisher-reports__card-body">
                        <div className="publisher-reports__card-row">Amount: ${report.amount.toFixed(2)}</div>
                        <div className="publisher-reports__card-row">Number of Orders: {report.orders.length}</div>
                        <div className="publisher-reports__card-row">
                          <form onSubmit={(e) => handleFileUpload(e, index)} className="publisher-reports__upload-form">
                            <div className="publisher-reports__file-input-wrapper small-input">
                              <input
                                type="file"
                                id={`fileInput-${index}`}
                                className="publisher-reports__file-input"
                                accept="application/pdf"
                                onChange={(e) => handleFileChange(e, index)}
                              />
                              <label htmlFor={`fileInput-${index}`} className="publisher-reports__file-label small-label">
                                <Upload size={12} />
                                <span>Upload</span>
                              </label>
                            </div>
                            <button type="submit" className="publisher-reports__submit-btn small-btn" disabled={uploading[index] || !files[index]}>
                              {uploading[index] ? (
                                <MySpinner small />
                              ) : (
                                'Submit'
                              )}
                            </button>
                          </form>
                        </div>
                        <div className="publisher-reports__card-row">
                          <div className="publisher-reports__invoice-actions">
                            {(report.invoices || []).map(invoice => (
                              <div key={invoice.id} className="publisher-reports__invoice-action-row">
                                <button 
                                  className="publisher-reports__download-btn"
                                  onClick={() => handleDownloadInvoice(invoice.id, invoice.fileName)}
                                  disabled={downloadingInvoices.includes(invoice.id)}
                                >
                                  {downloadingInvoices.includes(invoice.id) ? (
                                    <MySpinner small />
                                  ) : (
                                    <>
                                      <Download size={16} />
                                      <span>{invoice.fileName}</span>
                                    </>
                                  )}
                                </button>
                                <button 
                                  className="publisher-reports__delete-btn"
                                  onClick={() => confirmAndDeleteInvoice(invoice)}
                                  title="Delete Invoice"
                                  disabled={deletingInvoices.includes(invoice.id)}
                                >
                                  {deletingInvoices.includes(invoice.id) ? (
                                    <MySpinner small />
                                  ) : (
                                  <Trash2 size={16} />
                                  )}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => {
            handleDeleteInvoice(invoiceToDelete.id);
            setShowDeleteModal(false);
          }}
        />
      )}
    </PageLayout>
  );
};

export default PublisherReports;