// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 1rem;
    width: 100%;
}

.nav-pills {
    display: flex;
    gap: 1rem;
}

.nav-pills .nav-link {
    color: #495057;
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0;
}

.nav-pills .nav-link.active {
    color: #0d6efd;
    background: none;
    border-bottom: 2px solid #0d6efd;
}

.content-area {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    width: 100%;
}

.main {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

h2 {
    font-size: 1.5rem;
    margin: 0;
}

/* Ensure table takes full width */
.content-area table {
    width: 100%;
}

/* Add responsive container */
.table-responsive {
    width: 100%;
    overflow-x: auto;
} `, "",{"version":3,"sources":["webpack://./src/components/ui/dashboard/AdminDashboard.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,oCAAoC;IACpC,WAAW;AACf;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,SAAS;AACb;;AAEA,kCAAkC;AAClC;IACI,WAAW;AACf;;AAEA,6BAA6B;AAC7B;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".nav-container {\n    border-bottom: 1px solid #dee2e6;\n    padding-bottom: 1rem;\n    width: 100%;\n}\n\n.nav-pills {\n    display: flex;\n    gap: 1rem;\n}\n\n.nav-pills .nav-link {\n    color: #495057;\n    background: none;\n    border: none;\n    padding: 0.5rem 1rem;\n    border-radius: 0;\n}\n\n.nav-pills .nav-link.active {\n    color: #0d6efd;\n    background: none;\n    border-bottom: 2px solid #0d6efd;\n}\n\n.content-area {\n    background: white;\n    border-radius: 8px;\n    padding: 1.5rem;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n    width: 100%;\n}\n\n.main {\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1.5rem;\n    width: 100%;\n}\n\nh2 {\n    font-size: 1.5rem;\n    margin: 0;\n}\n\n/* Ensure table takes full width */\n.content-area table {\n    width: 100%;\n}\n\n/* Add responsive container */\n.table-responsive {\n    width: 100%;\n    overflow-x: auto;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
