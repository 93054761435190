import React, { useState } from 'react';
import { 
    Ticket, TrendingUp, Users, DollarSign, 
    Calendar, Filter, Edit2, Ban, Copy, 
    Check, Archive, RefreshCw
} from 'lucide-react';

const StatCard = ({ title, value, subtext, trend, icon: Icon }) => (
    <div className="card">
        <div className="card-body p-4">
            <div className="d-flex justify-content-between align-items-start">
                <div>
                    <h6 className="text-muted">{title}</h6>
                    <h3 className="mb-0">{value}</h3>
                    <small className={`text-${trend?.type || 'muted'}`}>{subtext}</small>
                </div>
                {Icon && <Icon className="text-muted" size={20} />}
            </div>
        </div>
    </div>
);

export default function VoucherManagement() {
    const [selectedVouchers, setSelectedVouchers] = useState([]);
    const [filters, setFilters] = useState({
        status: 'all',
        type: 'all',
        date: 'all'
    });

    // Mock stats data
    const stats = {
        activeVouchers: {
            count: 45,
            change: 8
        },
        totalRedemptions: {
            count: 1234,
            change: 15
        },
        conversionRate: {
            rate: '68%',
            change: 5
        },
        revenue: {
            amount: 45678,
            change: 12
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedVouchers(['all']);
        } else {
            setSelectedVouchers([]);
        }
    };

    const handleSelectVoucher = (voucherId) => {
        setSelectedVouchers(prev => {
            if (prev.includes(voucherId)) {
                return prev.filter(id => id !== voucherId);
            }
            return [...prev, voucherId];
        });
    };

    return (
        <div className=" p-0">
            {/* Filters */}
            <div className="row g-3 mb-4">
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.status}
                        onChange={(e) => setFilters({...filters, status: e.target.value})}
                    >
                        <option value="all">All Status</option>
                        <option value="active">Active</option>
                        <option value="expired">Expired</option>
                        <option value="scheduled">Scheduled</option>
                        <option value="paused">Paused</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.type}
                        onChange={(e) => setFilters({...filters, type: e.target.value})}
                    >
                        <option value="all">All Types</option>
                        <option value="percentage">Percentage Discount</option>
                        <option value="fixed">Fixed Amount</option>
                        <option value="freebie">Freebie</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <select 
                        className="form-select"
                        value={filters.date}
                        onChange={(e) => setFilters({...filters, date: e.target.value})}
                    >
                        <option value="all">All Dates</option>
                        <option value="today">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <button className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                        <Filter className="w-4 h-4 me-2" />
                        Apply Filters
                    </button>
                </div>
            </div>

            {/* Statistics Cards */}
            <div className="row g-4 mb-4">
                <div className="col-md-3">
                    <StatCard
                        title="Active Vouchers"
                        value={stats.activeVouchers.count}
                        subtext={`+${stats.activeVouchers.change}% this month`}
                        trend={{ type: 'success' }}
                        icon={Ticket}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Total Redemptions"
                        value={stats.totalRedemptions.count}
                        subtext={`+${stats.totalRedemptions.change}% vs last month`}
                        trend={{ type: 'success' }}
                        icon={Users}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Conversion Rate"
                        value={stats.conversionRate.rate}
                        subtext={`+${stats.conversionRate.change}% this month`}
                        trend={{ type: 'success' }}
                        icon={TrendingUp}
                    />
                </div>
                <div className="col-md-3">
                    <StatCard
                        title="Total Revenue Impact"
                        value={`$${stats.revenue.amount.toLocaleString()}`}
                        subtext={`+${stats.revenue.change}% vs last month`}
                        trend={{ type: 'success' }}
                        icon={DollarSign}
                    />
                </div>
            </div>

            {/* Vouchers Table */}
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th width="40">
                                        <input 
                                            type="checkbox" 
                                            className="form-check-input"
                                            onChange={handleSelectAll}
                                            checked={selectedVouchers.includes('all')}
                                        />
                                    </th>
                                    <th>Code</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                    <th>Usage / Limit</th>
                                    <th>Valid Period</th>
                                    <th>Status</th>
                                    <th>Performance</th>
                                    <th width="120">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            className="form-check-input"
                                            checked={selectedVouchers.includes('1')}
                                            onChange={() => handleSelectVoucher('1')}
                                        />
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <div className="fw-medium">SUMMER2024</div>
                                                <small className="text-muted">Campaign: Summer Sale</small>
                                            </div>
                                            <button 
                                                className="btn btn-sm btn-link ms-2"
                                                onClick={() => navigator.clipboard.writeText('SUMMER2024')}
                                            >
                                                <Copy size={14} />
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Percentage</div>
                                        <small className="text-muted">All Products</small>
                                    </td>
                                    <td>
                                        <div>25% OFF</div>
                                        <small className="text-muted">Max: $100</small>
                                    </td>
                                    <td>
                                        <div>234 / 500</div>
                                        <div className="progress" style={{ height: '4px' }}>
                                            <div 
                                                className="progress-bar bg-success" 
                                                style={{ width: '46.8%' }}
                                            ></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>Jan 1 - Mar 31, 2024</div>
                                        <small className="text-warning">Expires in 25 days</small>
                                    </td>
                                    <td>
                                        <span className="badge bg-success">Active</span>
                                    </td>
                                    <td>
                                        <div>$12,345</div>
                                        <small className="text-success">46.8% used</small>
                                    </td>
                                    <td>
                                        <div className="d-flex gap-2">
                                            <button 
                                                className="btn btn-sm btn-outline-primary"
                                                title="Edit Voucher"
                                            >
                                                <Edit2 size={14} />
                                            </button>
                                            <button 
                                                className="btn btn-sm btn-outline-warning"
                                                title="Pause Voucher"
                                            >
                                                <Ban size={14} />
                                            </button>
                                            <button 
                                                className="btn btn-sm btn-outline-secondary"
                                                title="Archive Voucher"
                                            >
                                                <Archive size={14} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <p className="text-muted mb-0">Showing 1-10 of 45 vouchers</p>
                        <nav>
                            <ul className="pagination mb-0">
                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}