import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Table, Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import ArrowSpan from "./ArrowSpan";
import PortalLinkCell from "./PortalLinkCell";
import { useCart } from "../CartContext";
import { jwtDecode } from "jwt-decode";
import {linkTypes} from "./LinkTypes"; // Adjust the path as necessary
import { LinkType, getLinkTypeMultiplier, getVAT } from './SmallCalculatorHelper';
import { BASE_API_URL } from "../config/constants";
import { useMediaQuery } from 'react-responsive';
import './BulkOrder.css';
import BulkOrderPageLayout from '../components/BulkOrderPageLayout';

const ProgressSteps = () => {
  return (
    <Row className="align-items-center my-3">
      <Col xs={12} md={8}>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <div className="position-absolute" style={{ 
            height: "8px", 
            background: "#dee2e6",
            top: "35px",
            left: "10%",
            right: "10%",
            zIndex: 1
          }}></div>
          
          <div className="position-absolute" style={{ 
            height: "8px", 
            background: "#198754",
            width: "70%",
            top: "35px",
            left: "10%",
            zIndex: 2
          }}></div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-success text-white d-flex align-items-center justify-content-center mx-auto mb-2" 
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
            </div>
            <h6 className="mb-1">Keyword & Strategy</h6>
            <h6 className="mb-0">Details</h6>
          </div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-success text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
              </svg>
            </div>
            <h6 className="mb-1">Filtered websites</h6>
            <h6 className="mb-0">and articles</h6>
          </div>

          <div className="text-center" style={{ zIndex: 3 }}>
            <div className="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2"
                 style={{ width: "60px", height: "60px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
              </svg>
            </div>
            <h6 className="mb-1">Order Confirmation</h6>
            <h6 className="mb-0">& details</h6>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const EditableTitle = ({ initialValue, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleBlur = () => {
    setIsEditing(false);
    if (value !== initialValue) {
      onSave(value);
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      autoFocus
      className="form-control form-control-sm"
    />
  ) : (
    <div
      onClick={() => setIsEditing(true)}
      className="editable-cell"
      style={{
        cursor: 'pointer',
        padding: '4px',
        border: '1px dashed #ccc',
        borderRadius: '4px',
        minHeight: '30px'
      }}
    >
      {value}
      <small className="d-block text-muted" style={{ fontSize: '0.7rem' }}>
        (Click to edit)
      </small>
    </div>
  );
};

// Link Type Select Component
const LinkTypeSelect = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="form-select form-select-sm"
    style={{ 
      cursor: 'pointer',
      border: '1px dashed #ccc'
    }}
  >
    <option value="NormalFollow">Follow</option>
    <option value="NoFollow">No Follow</option>
    <option value="UGC">UGC</option>
    <option value="Sponsored">Sponsored</option>
    <option value="GeneralKeyword">General Keyword</option>
    {/* <option value="GeneralKeyword">General Keyword</option> */}
  </select>
);

const MobileItemCard = ({ item, handleCheck, handleTitleChange, handleLinkTypeChange, handleGetOtherArticle, handleChangeDomain, isItemInCart }) => {
  const inCart = isItemInCart(item);
  return (
    <div 
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        border: '1px solid #dee2e6',
        marginBottom: '1rem',
        backgroundColor: inCart ? '#f5f5f5' : 'white',
        opacity: inCart ? 0.7 : 1
      }}
    >
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="card-subtitle text-muted">Portal</h6>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={item.checked}
              onChange={() => handleCheck(item.id)}
              disabled={inCart || item.loading}
            />
          </div>
        </div>
        
        <PortalLinkCell 
          item={item}
          handleGetOtherArticle={handleGetOtherArticle}
          handleChangeDomain={handleChangeDomain}
          isChangeDomainDisabled={item.allDomains?.length <= 1}
          isGetOtherArticleDisabled={item.domainTargetUrls?.length <= 1}
          isMobile={true}
        />

        <div className="row g-3 mt-2">
          <div className="col-6">
            <small className="text-muted d-block">Domain Authority</small>
            <strong>{item.domainAuthority}</strong>
          </div>
          <div className="col-6">
            <small className="text-muted d-block">Keyword</small>
            <strong>{item.keyword}</strong>
          </div>
          <div className="col-12">
            <small className="text-muted d-block">Link Type</small>
            <LinkTypeSelect
              value={item.linkType}
              onChange={(newValue) => handleLinkTypeChange(item.id, newValue)}
            />
          </div>
          <div className="col-6">
            <small className="text-muted d-block">Sentiment</small>
            <span>{item.sentiment}</span>
          </div>
          <div className="col-6">
            <small className="text-muted d-block">Language</small>
            <span>{item.language}</span>
          </div>
          <div className="col-12">
            <small className="text-muted d-block">Anchor Title</small>
            <EditableTitle
              initialValue={item.keyword}
              onSave={(newValue) => handleTitleChange(item.id, newValue)}
            />
          </div>
          <div className="col-12">
            <small className="text-muted d-block">Price / Month</small>
            <strong className="text-success">${item.price} USD</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

const BulkOrder = () => {
  const { addToCart, cartItems } = useCart();
  const isItemInCart = (item) => {
    return cartItems.some(cartItem => 
      cartItem.keyword === item.keyword && 
      cartItem.url === item.yourUrlToBeLinked && 
      cartItem.publisherWebsite === item.portal
    );
  };

  const handleTitleChange = (id, newTitle) => {
    setSelectedItems(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item, title: newTitle } : item
      )
    );
  };

  const handleLinkTypeChange = (id, newLinkType) => {
    setSelectedItems(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item,
           linkType: newLinkType,
           price: item.basePrice * getLinkTypeMultiplier(newLinkType)
           } : item
      )
    );
    console.log('hahahaha', id);
    setHighlightedId(id);
  };
  const [highlightedId, setHighlightedId] = useState(null);
  useEffect(() => {
    if (highlightedId !== null) {
      const timer = setTimeout(() => {
        setHighlightedId(null); // Reset after 1.5 seconds
      }, 1500); // 1.5 seconds
      return () => clearTimeout(timer); // Cleanup timer if the component unmounts
    }
  }, [highlightedId]);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});
  const [finalFormData, setFinalFormData] = useState(null);
  const [loadingCallApi, setLoadingCallApi] = useState(true);

  const jobId = location.pathname.split('/').pop(); // Extract jobId from URL
  useEffect(() => {
    const fetchJobStatus = async () => {
      if (!jobId) {
        navigate('/bulkorder1'); // Redirect if no jobId
        return;
      }

      setLoadingCallApi(true);
      setLoading(true);
      try {
        const response = await fetch(`${BASE_API_URL}search/status/${jobId}`);

        console.log(response);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        setFinalFormData(data);
      } catch (err) {
        // setError(err.message);
      } finally {
        setLoadingCallApi(false);
        setLoading(false);
      }
    };

    fetchJobStatus();
  }, [jobId, navigate]);
  
  const token = localStorage.getItem('token');
  const getUserIdFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.nameid; // Adjust based on your token's structure
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
};
const userId = getUserIdFromToken(token);
const [selectedItems, setSelectedItems] = useState([]);

useEffect(() => {
  console.log(finalFormData);
  if (!finalFormData || !finalFormData.result) {
    return;
  }

  const updatedItems = finalFormData.result.chatgptResults.map((item, index) => {
    // Flatten rankedArticles for the current item
    const rankedArticles = item.item.rankedArticles || [];
    if (rankedArticles.length === 0) {
      console.warn(`No ranked articles found for item: ${item}`);
      return null;
    }

    // Get the first article as default
    const firstArticle = rankedArticles[0];

    // Extract all unique domains
    const allDomains = [...new Set(rankedArticles.map((article) => article.domain))];

    // Create array of target URLs for the first article's domain
    const currentDomainTargetUrls = firstArticle.targetUrls || [];

    // Store all target URLs by domain for reference
    const targetUrlsByDomain = rankedArticles.reduce((acc, article) => {
      if (article.targetUrls && article.targetUrls.length > 0) {
        acc[article.domain] = article.targetUrls;
      }
      return acc;
    }, {});

    // Calculate initial price based on link type
    const linkTypeMultiplier = getLinkTypeMultiplier("NormalFollow"); // TODO: Adjust as needed
    const initialPrice = firstArticle.price * linkTypeMultiplier;

    // Return structured data for this item
    return {
      id: index,
      ...item,
      loading: false,
      rankedArticles: rankedArticles,
      portal: firstArticle.domain,
      targetUrl: firstArticle.targetUrls?.[0] || "",
      allDomains: allDomains,
      domainTargetUrls: currentDomainTargetUrls,
      targetUrlsByDomain: targetUrlsByDomain,
      domainAuthority: firstArticle.domainAuthority || 0,
      basePrice: firstArticle.price,
      price: initialPrice,
      error: false,

      title: item.item.keyword || "Unknown", // set title first time to keyword
      url: finalFormData.result.yourUrlToBeLinked, // set url
      keyword: item.item.keyword || "Unknown",
      linkType: finalFormData.result.typeOfLink || "Follow",
      sentiment: finalFormData.result.sentiment || "Neutral",
      language: finalFormData.result.language || "Unknown",
      country: finalFormData.result.country || "Unknown",
      theme: finalFormData.result.theme || "General",
    };
  });

  // Filter out any null values in case of missing rankedArticles
  const validItems = updatedItems.filter((item) => item !== null);
  console.log(validItems);
  // Update the state
  setSelectedItems(validItems);
  console.log("Updated Selected Items Array:", validItems);
}, [finalFormData]);

  
const fetchRankedArticles = async (item) => {
  const singleRankRequest = {
    Item: {
      Keyword: item.keyword,
      Articles: item.rankedArticles,
    },
    DesiredSentiment: finalFormData.sentiment,
  };

  try {
    setLoadingRows((prev) => ({ ...prev, [item.id]: true })); // Set loading for this row
    const response = await fetch(`${BASE_API_URL}search/single-rank-articles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(singleRankRequest),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ranked articles for keyword: ${item.keyword}`);
    }

    const rankingData = await response.json();

    // Update the item with ranked articles
    setSelectedItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? {
              ...prevItem,
              rankedArticles: rankingData.rankedArticles || [],
            }
          : prevItem
      )
    );
  } catch (error) {
    console.error(`Error fetching ranked articles for keyword: ${item.keyword}`, error);
  } finally {
    setLoadingRows((prev) => ({ ...prev, [item.id]: false })); // Mark row as not loading
  }
};

const fetchRankedArticle = async (item) => {
  const requestPayload = {
    Item: {
      Keyword: item.keyword,
      Articles: item.articles,
    },
    YourUrl: finalFormData.url,
    DesiredSentiment: item.sentiment,
  };
  console.log(requestPayload);

  try {
    const response = await fetch(`${BASE_API_URL}Search/single-rank-articles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ranked article');
    }

    const rankedData = await response.json();
    const rankedArticles = rankedData.item.rankedArticles || [];
    
    if (rankedArticles.length === 0) {
      throw new Error('No ranked articles received');
    }

    // Get the first article as default
    const firstArticle = rankedArticles[0];
    
    // Extract all unique domains
    const allDomains = [...new Set(rankedArticles.map(article => article.domain))];
    
    // Create array of target URLs for current domain
    const currentDomainTargetUrls = firstArticle.targetUrls || [];

    // Store all target URLs by domain for reference
    const targetUrlsByDomain = rankedArticles.reduce((acc, article) => {
      if (article.targetUrls && article.targetUrls.length > 0) {
        acc[article.domain] = article.targetUrls;
      }
      return acc;
    }, {});

    // Calculate initial price based on link type
    const linkTypeMultiplier = getLinkTypeMultiplier(item.linkType);
    const initialPrice = firstArticle.price * linkTypeMultiplier;
    return {
      ...item,
      loading: false,
      rankedArticles: rankedArticles,
      portal: firstArticle.domain,
      targetUrl: firstArticle.targetUrls?.[0] || "",
      allDomains: allDomains,
      domainTargetUrls: currentDomainTargetUrls, // Array of target URLs for current domain
      targetUrlsByDomain: targetUrlsByDomain, // Object mapping domains to their target URLs
      domainAuthority: firstArticle.domainAuthority || 0,
      basePrice: firstArticle.price,
      price: initialPrice,
      error: false
    };
  } catch (error) {
    console.error('Error fetching ranked article:', error);
    return {
      ...item,
      loading: false,
      error: true
    };
  }
};

  
const rankArticle = async (item) => {
  const updatedItem = await fetchRankedArticle(item); // Fetch ranked article data
  console.log("updated item", updatedItem);

  // Check if all required fields are valid (i.e., not null or undefined)
  const hasData =
    updatedItem.rankedArticles

  // Check if there's an error in the response
  const hasError = updatedItem.error;

  setSelectedItems((prevItems) => {
    if (hasData && !hasError) {
      // If all required data is present and no error, update the item
      return prevItems.map((prevItem) =>
        prevItem.id === item.id ? { ...prevItem, ...updatedItem, loading: false } : prevItem
      );
    } else {
      // If no valid data or there's an error, remove the item
      return prevItems.filter((prevItem) => prevItem.id !== item.id);
    }
  });
};

const [allLoaded, setAllLoaded] = useState(false);


// Function to call `rank-articles-single` for each article
useEffect(() => {
  if (selectedItems.some((item) => item.loading)) {
    selectedItems.forEach((item) => {
      if (item.loading) {
        rankArticle(item); // Trigger ranking for each loading item
      }
    });
  } else {
    setAllLoaded(true); // All items have finished loading
  }
}, []);

const handleChangeDomain = (id) => {
  setSelectedItems(prevState =>
    prevState.map(item => {
      if (item.id === id) {
        const currentDomainIndex = item.allDomains.indexOf(item.portal);
        const nextDomainIndex = (currentDomainIndex + 1) % item.allDomains.length;
        const newDomain = item.allDomains[nextDomainIndex];
        
        // Find the ranked article data for the new domain
        const newDomainData = item.rankedArticles.find(ra => ra.domain === newDomain);
        
        if (!newDomainData) {
          return item;
        }

        // Get target URLs for the new domain from the stored mapping
        const newDomainTargetUrls = newDomainData.targetUrls || [];
        
        // Calculate new price with link type multiplier
        const linkTypeMultiplier = getLinkTypeMultiplier(item.linkType);
        const newPrice = newDomainData.price * linkTypeMultiplier;

        return {
          ...item,
          portal: newDomain,
          targetUrl: newDomainTargetUrls[0] || "",
          domainAuthority: newDomainData.domainAuthority || item.domainAuthority,
          basePrice: newDomainData.price,
          price: newPrice,
          domainTargetUrls: newDomainTargetUrls
        };
      }
      return item;
    })
  );
};



  const handleCheck = (id) => {
    setSelectedItems(prevState =>
      prevState.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item, checked: !item.checked };
          console.log("Updated item:", updatedItem); // Debugging line to check the updated item
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleGetOtherArticle = (id) => {
    setSelectedItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          const currentTargetUrls = item.domainTargetUrls;
          if (!Array.isArray(currentTargetUrls) || currentTargetUrls.length <= 1) {
            return item;
          }

          // Find the current targetUrl's index in the array
          const currentUrlIndex = currentTargetUrls.indexOf(item.targetUrl);
          
          // Get the next index, cycling back to 0 if at the end
          const nextIndex = (currentUrlIndex + 1) % currentTargetUrls.length;
          
          // Get the next URL
          const newTargetUrl = currentTargetUrls[nextIndex];
          
          return {
            ...item,
            targetUrl: newTargetUrl,
          };
        }
        return item;
      })
    );
  };
  
  const generateGUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };
  const handleAddToCart = () => {
    const itemsToAdd = selectedItems
      .filter(item => item.checked) // Only select checked items
      .filter(item => !isItemInCart(item))
      .map(item => {
        return {
          id: userId,
          keyword: item.keyword,
          url: finalFormData.result.yourUrlToBeLinked,
          title: item.title,
          exclusivity: false,
          typeOfLink: item.linkType,
          publisherWebsite: item.portal,
          monthlyValue: item.price + getVAT(item.price),
          domainAuthority: item.domainAuthority,
          targetUrl: item.targetUrl,
        };
      });
  
    console.log('Items being added to cart:', itemsToAdd);
  
    itemsToAdd.forEach(item => addToCart(item));
  };
// Function to calculate the price without VAT
const calculatePriceWithoutVAT = (monthlyValue) => monthlyValue;

// Function to calculate VAT for each item
const calculateVAT = (monthlyValue) => getVAT(monthlyValue);

// Calculate the total without VAT (for all selected items)
const calculateTotalWithoutVAT = () => {
  return selectedItems.reduce((total, item) => {
    if (item.checked) {
      return total + calculatePriceWithoutVAT(item.price);
    }
    return total;
  }, 0);
};

// Calculate the total VAT for all selected items
const calculateTotalVAT = () => {
  return selectedItems.reduce((total, item) => {
    if (item.checked) {
      return total + calculateVAT(item.price);
    }
    return total;
  }, 0);
};

// Calculate the total including VAT
const calculateTotal = () => {
  const totalWithoutVAT = calculateTotalWithoutVAT();
  const totalVAT = calculateTotalVAT();
  return totalWithoutVAT + totalVAT;
};

const isMobile = useMediaQuery({ maxWidth: 768 });

const tableHeaders = [
  'Portal',
  'DA',
  'Keyword',
  'Type of Link',
  'Theme',
  'Anchor Title',
  'Price / Month',
  'Order'
];

const tableRef = useRef(null);

// Add new state for collapsed columns
const [collapsedColumns, setCollapsedColumns] = useState(new Set());

// Update the column resize initialization
const initializeColumnResize = () => {
  if (!tableRef.current) return;
  
  const cols = tableRef.current.querySelectorAll('th');
  
  cols.forEach((col, index) => {
    // Remove any existing event listeners
    col.removeEventListener('mousedown', col.resizeHandler);
    
    // Create resize handle element
    const handle = document.createElement('div');
    handle.className = 'resize-handle';
    col.appendChild(handle);
    
    // Create handler function
    col.resizeHandler = function(e) {
      if (!e.target.classList.contains('resize-handle')) return;
      
      e.preventDefault();
      handle.classList.add('dragging');
      const startX = e.pageX;
      const startWidth = col.offsetWidth;
      
      function onMouseMove(e) {
        const width = startWidth + (e.pageX - startX);
        
        // If width is less than collapse threshold, collapse the column
        if (width < 40) {
          col.classList.add('collapsed');
          setCollapsedColumns(prev => new Set([...prev, index]));
        } else {
          col.classList.remove('collapsed');
          setCollapsedColumns(prev => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
          });
          col.style.width = `${width}px`;
        }
      }
      
      function onMouseUp() {
        handle.classList.remove('dragging');
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      }
      
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };
    
    // Add the new event listener
    col.addEventListener('mousedown', col.resizeHandler);
  });
};

useEffect(() => {
  if (!isMobile && selectedItems.length > 0) {
    // Add a small delay to ensure the table is rendered
    const timer = setTimeout(() => {
      initializeColumnResize();
    }, 100);
    
    return () => {
      clearTimeout(timer);
      // Cleanup event listeners when component unmounts
      if (tableRef.current) {
        const cols = tableRef.current.querySelectorAll('th');
        cols.forEach(col => {
          if (col.resizeHandler) {
            col.removeEventListener('mousedown', col.resizeHandler);
          }
        });
      }
    };
  }
}, [isMobile, selectedItems]);

// Add these new state variables at the top of the BulkOrder component
const [visibleColumns, setVisibleColumns] = useState(tableHeaders.map((_, i) => i)); // All columns visible by default
const [columnOrder, setColumnOrder] = useState(tableHeaders.map((_, i) => i)); // Default order
const [draggedColumn, setDraggedColumn] = useState(null);

// Add this function to handle column dragging
const handleColumnDrag = (e, columnIndex) => {
  e.preventDefault();
  setDraggedColumn(columnIndex);
};

// Add this function to handle column dropping
const handleColumnDrop = (e, targetIndex) => {
  e.preventDefault();
  if (draggedColumn === null) return;

  const newOrder = [...columnOrder];
  const draggedItem = newOrder[draggedColumn];
  
  // Remove dragged item
  newOrder.splice(draggedColumn, 1);
  // Insert at new position
  newOrder.splice(targetIndex, 0, draggedItem);
  
  setColumnOrder(newOrder);
  setDraggedColumn(null);
};

// Move renderCell to the top of the component, before any JSX
const renderCell = (item, colIndex) => {
  switch (colIndex) {
    case 0: // Portal
      return (
        <PortalLinkCell 
          item={item}
          handleGetOtherArticle={handleGetOtherArticle}
          handleChangeDomain={handleChangeDomain}
          isChangeDomainDisabled={item.allDomains?.length <= 1}
          isGetOtherArticleDisabled={item.domainTargetUrls?.length <= 1}
        />
      );
    case 1: // DA
      return item.domainAuthority;
    case 2: // Keyword
      return item.keyword;
    case 3: // Type of Link
      return (
        <LinkTypeSelect
          value={item.linkType}
          onChange={(newValue) => handleLinkTypeChange(item.id, newValue)}
        />
      );
    case 4: // Theme
      return item.theme;
    case 5: // Anchor Title
      return (
        <EditableTitle
          initialValue={item.keyword}
          onSave={(newValue) => handleTitleChange(item.id, newValue)}
        />
      );
    case 6: // Price
      return <span className="text-success">${item.price} USD</span>;
    case 7: // Order
      return (
        <div 
          className="order-checkbox-container"
          onClick={() => !isItemInCart(item) && !item.loading && handleCheck(item.id)}
          style={{ cursor: isItemInCart(item) || item.loading ? 'not-allowed' : 'pointer' }}
        >
          <input
            type="checkbox"
            className="custom-checkbox"
            id={`order-checkbox-${item.id}`}
            checked={item.checked}
            onChange={() => handleCheck(item.id)}
            disabled={isItemInCart(item) || item.loading}
          />
          <label 
            htmlFor={`order-checkbox-${item.id}`} 
            className="checkbox-label"
          />
        </div>
      );
    default:
      return null;
  }
};

// Update the TableSummary component
const TableSummary = ({ items }) => {
  const summary = items[0] || {};
  const [show, setShow] = useState(false);
  
  const handleCheckboxClick = (e, index) => {
    e.stopPropagation();
    setVisibleColumns(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index].sort()
    );
  };

  return (
    <div className="table-summary-container mb-3">
      <div className="d-flex align-items-center">
        <div className="column-visibility-menu me-4">
          <Dropdown show={show} onToggle={(isOpen) => setShow(isOpen)}>
            <Dropdown.Toggle variant="secondary">
              Show/Hide Columns
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {tableHeaders.map((header, index) => (
                <Dropdown.Item 
                  key={index} 
                  as="label" 
                  onClick={(e) => e.stopPropagation()}
                >
                  <input
                    type="checkbox"
                    checked={visibleColumns.includes(index)}
                    onChange={(e) => handleCheckboxClick(e, index)}
                    className="me-2"
                  />
                  {header}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="summary-items d-flex align-items-center">
          <div className="summary-item me-4" style={{marginTop: '20px'}}>
            <span className="summary-label me-2">Sentiment:</span>
            <span className="summary-value badge bg-light text-dark">{summary.sentiment}</span>
          </div>
          <div className="summary-item me-4" style={{marginTop: '20px'}}>
            <span className="summary-label me-2">Country:</span>
            <span className="summary-value badge bg-light text-dark">{summary.country}</span>
          </div>
          <div className="summary-item" style={{marginTop: '20px'}}>
            <span className="summary-label me-2">Language:</span>
            <span className="summary-value badge bg-light text-dark">{summary.language}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add these states at the top of the component
const [sortConfig, setSortConfig] = useState({
  key: null,
  direction: 'asc'
});

// Update the handleSort function
const handleSort = (columnIndex) => {
  console.log('Sorting column:', columnIndex);
  
  // Prevent multiple rapid clicks
  const now = Date.now();
  if (lastClickTime.current && now - lastClickTime.current < 300) {
    return;
  }
  lastClickTime.current = now;

  setSortConfig(prevConfig => {
    const isSameColumn = prevConfig.key === columnIndex;
    const newDirection = isSameColumn && prevConfig.direction === 'asc' ? 'desc' : 'asc';
    
    console.log('Changing sort:', {
      prevKey: prevConfig.key,
      newKey: columnIndex,
      prevDirection: prevConfig.direction,
      newDirection,
      isSameColumn
    });

    return {
      key: columnIndex,
      direction: newDirection
    };
  });
};

// Add this at the top of your component with other state declarations
const lastClickTime = useRef(null);

// Keep the debug useEffect
useEffect(() => {
  console.log('Sort config changed:', {
    key: sortConfig.key,
    direction: sortConfig.direction,
    timestamp: new Date().toISOString()
  });
}, [sortConfig]);

// Update the getSortedItems function
const getSortedItems = (items) => {
  if (!sortConfig.key) return items;

  return [...items].sort((a, b) => {
    let aValue, bValue;

    switch (sortConfig.key) {
      case 0: // Portal
        aValue = String(a.portal || '').toLowerCase();
        bValue = String(b.portal || '').toLowerCase();
        break;
      case 1: // DA
        aValue = parseInt(a.domainAuthority) || 0;
        bValue = parseInt(b.domainAuthority) || 0;
        break;
      case 2: // Keyword
        aValue = String(a.keyword || '').toLowerCase();
        bValue = String(b.keyword || '').toLowerCase();
        break;
      case 3: // Type of Link
        aValue = String(a.linkType || '').toLowerCase();
        bValue = String(b.linkType || '').toLowerCase();
        break;
      case 4: // Theme
        aValue = String(a.theme || '').toLowerCase();
        bValue = String(b.theme || '').toLowerCase();
        break;
      case 5: // Anchor Title
        aValue = String(a.keyword || '').toLowerCase();
        bValue = String(b.keyword || '').toLowerCase();
        break;
      case 6: // Price
        aValue = parseFloat(String(a.price).replace(/[^0-9.-]+/g, '')) || 0;
        bValue = parseFloat(String(b.price).replace(/[^0-9.-]+/g, '')) || 0;
        break;
      case 7: // Order (checkbox)
        aValue = a.checked ? 1 : 0;
        bValue = b.checked ? 1 : 0;
        break;
      default:
        return 0;
    }

    // Unified comparison logic
    const compareResult = typeof aValue === 'string'
      ? aValue.localeCompare(bValue)
      : aValue - bValue;

    return sortConfig.direction === 'asc' ? compareResult : -compareResult;
  });
};

useEffect(() => {
  if (selectedItems.length > 0) {
    console.log('Sample item structure:', selectedItems[0]);
  }
}, [selectedItems]);

// Update to use a single search state
const [searchTerm, setSearchTerm] = useState('');

// Update the search handler
const handleSearch = (e) => {
  e.stopPropagation();
  setSearchTerm(e.target.value);
};

// Update the filter function to search both portal and article
const getFilteredItems = (items) => {
  if (!searchTerm) return items;
  
  const searchLower = searchTerm.toLowerCase();
  return items.filter(item => 
    item.portal?.toLowerCase().includes(searchLower) || 
    item.targetUrl?.toLowerCase().includes(searchLower)
  );
};

// First, add this debug function at the top level of your component
const logItemData = (item) => {
  console.log('Item structure:', {
    keyword: item.keyword,
    itemKeyword: item.item?.keyword,
    fullItem: item
  });
};

// First, add these tooltip descriptions at the top of your component
const headerTooltips = {
  'Portal': 'Website where your link will be placed. Click "Change Domain" to switch to another available domain.',
  'DA': 'Domain Authority - Measure of website\'s authority (0-100)',
  'Keyword': 'Target keyword for the link',
  'Type of Link': 'Type of backlink (Follow, NoFollow, etc.)',
  'Theme': 'Content category or theme of the website'
};

return (
  <PageLayout>
    <div className="bulk-order-container">
      <ProgressSteps />
      
      <h4>Please select the websites and articles you wish to order</h4>
      <h6>
        Please select the websites and articles that best fit your link-building strategy. 
        Review key metrics such as Domain Authority, Traffic Value, and other important 
        metrics to ensure optimal placements for your content.
      </h6>

      {loadingCallApi ? (
        <div className="alert alert-info d-flex align-items-center gap-2">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span>Loading your data, please wait...</span>
        </div>
      ) : selectedItems.length === 0 ? (
        <div className="alert alert-warning">
          No matching domain for the current settings, change the settings and try again.
          <button 
            className="btn btn-sm btn-primary ms-3"
            onClick={() => navigate('/bulkorder1')}
          >
            Go Back
          </button>
        </div>
      ) : isMobile ? (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          {selectedItems.map((item) => (
            <MobileItemCard
              key={item.id}
              item={item}
              handleCheck={handleCheck}
              handleTitleChange={handleTitleChange}
              handleLinkTypeChange={handleLinkTypeChange}
              handleGetOtherArticle={handleGetOtherArticle}
              handleChangeDomain={handleChangeDomain}
              isItemInCart={isItemInCart}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="bulk-order-table-container">
            <TableSummary items={selectedItems} />
            <Table 
              ref={tableRef}
              bordered 
              hover 
              className="bulk-order-table"
              style={{ backgroundColor: 'white' }}
            >
              <thead>
                <tr>
                  {columnOrder.map((colIndex) => {
                    if (!visibleColumns.includes(colIndex)) return null;
                    const isCollapsed = collapsedColumns.has(colIndex);
                    return (
                      <th
                        key={colIndex}
                        className={`draggable-column ${isCollapsed ? 'collapsed' : ''} sortable-header`}
                      >
                        <div className="column-content">
                          {colIndex === 0 ? (
                            <div className="portal-header-container">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="portal-tooltip">{headerTooltips['Portal']}</Tooltip>}
                              >
                                <div className="portal-header" onClick={() => handleSort(colIndex)}>
                                  {tableHeaders[colIndex]}
                                  {sortConfig.key === colIndex && (
                                    <span className="sort-indicator">
                                      {sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}
                                    </span>
                                  )}
                                </div>
                              </OverlayTrigger>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search portal or article..."
                                value={searchTerm}
                                onChange={handleSearch}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          ) : (
                            <div onClick={() => handleSort(colIndex)}>
                              {headerTooltips[tableHeaders[colIndex]] ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-${colIndex}`}>
                                    {headerTooltips[tableHeaders[colIndex]]}
                                  </Tooltip>}
                                >
                                  <div className={`header-content ${
                                    sortConfig.key === colIndex 
                                      ? sortConfig.direction === 'asc'
                                        ? 'sort-asc'
                                        : 'sort-desc'
                                      : ''
                                  }`}>
                                    {tableHeaders[colIndex]}
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                <div className={`header-content ${
                                  sortConfig.key === colIndex 
                                    ? sortConfig.direction === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-desc'
                                    : ''
                                }`}>
                                  {tableHeaders[colIndex]}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {getSortedItems(getFilteredItems(selectedItems)).map((item) => (
                  <tr 
                    key={item.id}
                    style={{
                      backgroundColor: isItemInCart(item) ? '#f5f5f5' : 'white',
                      opacity: isItemInCart(item) ? 0.7 : 1,
                      pointerEvents: isItemInCart(item) ? 'none' : 'auto',
                      transition: highlightedId === item.id ? 'background-color 0.3s ease' : 'none',
                    }}
                  >
                    {columnOrder.map((colIndex) => {
                      if (!visibleColumns.includes(colIndex)) return null;
                      const isCollapsed = collapsedColumns.has(colIndex);
                      return (
                        <td 
                          key={colIndex} 
                          className={isCollapsed ? 'collapsed' : ''}
                          style={{ padding: '12px 8px' }}
                        >
                          {renderCell(item, colIndex)}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="price-section">
            <p>Price without VAT: ${calculateTotalWithoutVAT().toFixed(2)}</p>
            <p>VAT: ${calculateTotalVAT().toFixed(2)}</p>
            <h4>Total: ${calculateTotal().toFixed(2)} / Month</h4>
            <small>
              These hyperlinks are Links as a Service (LaaS),<br/>
              the links will remain active as long as you pay monthly.
            </small>

            <div className="action-buttons">
              <Button 
                onClick={handleAddToCart} 
                variant="primary"
              >
                Add to Cart
              </Button>
              <Button 
                variant="outline-secondary"
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  </PageLayout>
);
};

export default BulkOrder;
