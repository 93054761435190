import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { BASE_API_URL } from './config/constants';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner'; // Import your custom spinner
import { Alert } from 'react-bootstrap'; // Import Alert from React Bootstrap

const YourComponent = () => {
  const [companyDetails, setCompanyDetails] = useState({
    isCompany: false,
    name: '',
    address: '',
    vatNo: '',
    companyRegistryNo: '',
    bankAccount: '',
    representative: '',
    country: '',
    city: '',
    county: '',
    bank: '',
  });

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true); // Spinner state
  const [errors, setErrors] = useState({}); // Validation errors state
  const [error, setError] = useState(''); // for bootstrap alert
  const [success, setSuccess] = useState(''); // for bootstrap alert
  const getUserTypeFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userType;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const token = localStorage.getItem('token');
  const userType = getUserTypeFromToken(token);
  const userId = getUserIdFromToken(token);

  useEffect(() => {
    fetchCompanyDetails();
    fetchCountries();
  }, []);

  const fetchCompanyDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_API_URL}api/CompanyDetails/${userId}`);
      setCompanyDetails(response.data);
    } catch (error) {
      console.error('Error fetching company details:', error);
    } finally {
      // Wait for 1 second before setting loading to false
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  };
  

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setCompanyDetails(prevDetails => ({
      ...prevDetails,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const validateFields = () => {
    const requiredFields = companyDetails.isCompany
      ? ['name', 'address', 'vatNo', 'companyRegistryNo', 'bankAccount', 'representative', 'country', 'city', 'county', 'bank']
      : ['name', 'address', 'bankAccount', 'country', 'city', 'county', 'bank'];

    const newErrors = {};
    requiredFields.forEach(field => {
      if (!companyDetails[field]) {
        newErrors[field] = 'This field is required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    setLoading(true); // Show the spinner on submission
    try {
      await axios.put(`${BASE_API_URL}api/CompanyDetails/${userId}`, companyDetails);
      setSuccess('Details saved successfully');
    } catch (error) {
      console.error('Error saving details:', error);
      setError('Failed to save details'); 
    } finally {
      setLoading(false); // Hide the spinner after submission
    }
  };

  return (
    <>
      <PageLayout>
        <div className="container5" style={{'margin-left': '20px'}}>
                {/* Alert for error messages */}
          <div className="mt-0 subCont5 pt-0">
          {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}


            <h5>{userType === 'advertiser' ? 'Advertiser billing details' : 'Publisher billing details'}</h5>
            {/* <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p> */}

            {/* <h2>Details</h2> */}
            <p>Please fill in your details for invoicing and pricing</p>



          {loading ? (
              <MySpinner/>)
             : (
              

            <form onSubmit={handleSubmit}>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isCompany"
                  checked={companyDetails.isCompany}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="isCompany">
                  I am a company (not an individual)
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="name">{companyDetails.isCompany ? 'Company Name:' : 'Full Name:'}</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="name"
                  value={companyDetails.name}
                  onChange={handleInputChange}
                  placeholder={companyDetails.isCompany ? 'Enter company name' : 'Enter your full name'}
                />
                {errors.name && <div className="text-danger">{errors.name}</div>} {/* Display error message */}
              </div>

              <div className="form-group">
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="address"
                  value={companyDetails.address}
                  onChange={handleInputChange}
                  placeholder="Enter address"
                />
                {errors.address && <div className="text-danger">{errors.address}</div>} {/* Display error message */}
              </div>

              {companyDetails.isCompany && (
                <>
                  <div className="form-group">
                    <label htmlFor="vatNo">VAT NO:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.vatNo ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                      id="vatNo"
                      value={companyDetails.vatNo}
                      onChange={handleInputChange}
                      placeholder="Enter VAT number"
                    />
                    {errors.vatNo && <div className="text-danger">{errors.vatNo}</div>} {/* Display error message */}
                  </div>

                  <div className="form-group">
                    <label htmlFor="companyRegistryNo">Company Registry No:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.companyRegistryNo ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                      id="companyRegistryNo"
                      value={companyDetails.companyRegistryNo}
                      onChange={handleInputChange}
                      placeholder="Enter company registry number"
                    />
                    {errors.companyRegistryNo && <div className="text-danger">{errors.companyRegistryNo}</div>} {/* Display error message */}
                  </div>
                </>
              )}

              <div className="form-group">
                <label htmlFor="bankAccount">Bank Account:</label>
                <input
                  type="text"
                  className={`form-control ${errors.bankAccount ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="bankAccount"
                  value={companyDetails.bankAccount}
                  onChange={handleInputChange}
                  placeholder="Enter IBAN"
                />
                {errors.bankAccount && <div className="text-danger">{errors.bankAccount}</div>} {/* Display error message */}
              </div>

              {companyDetails.isCompany && (
                <div className="form-group">
                  <label htmlFor="representative">Company Representative:</label>
                  <input
                    type="text"
                    className={`form-control ${errors.representative ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                    id="representative"
                    value={companyDetails.representative}
                    onChange={handleInputChange}
                    placeholder="Enter name of the administrator"
                  />
                  {errors.representative && <div className="text-danger">{errors.representative}</div>} {/* Display error message */}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="country">Country:</label>
                <select
                  id="country"
                  className={`form-select ${errors.country ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  value={companyDetails.country || ''}
                  onChange={handleInputChange}
                >
                  <option value="">Select country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                  ))}
                </select>
                {errors.country && <div className="text-danger">{errors.country}</div>} {/* Display error message */}
              </div>

              <div className="form-group">
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  className={`form-control ${errors.city ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="city"
                  value={companyDetails.city}
                  onChange={handleInputChange}
                  placeholder="Enter city"
                />
                {errors.city && <div className="text-danger">{errors.city}</div>} {/* Display error message */}
              </div>

              <div className="form-group">
                <label htmlFor="county">County:</label>
                <input
                  type="text"
                  className={`form-control ${errors.county ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="county"
                  value={companyDetails.county}
                  onChange={handleInputChange}
                  placeholder="Enter county"
                />
                {errors.county && <div className="text-danger">{errors.county}</div>} {/* Display error message */}
              </div>

              <div className="form-group">
                <label htmlFor="bank">Bank:</label>
                <input
                  type="text"
                  className={`form-control ${errors.bank ? 'is-invalid' : ''}`} // Add 'is-invalid' for error state
                  id="bank"
                  value={companyDetails.bank}
                  onChange={handleInputChange}
                  placeholder="Enter bank name"
                />
                {errors.bank && <div className="text-danger">{errors.bank}</div>} {/* Display error message */}
              </div>

            <button 
              type="submit" 
              className="btn btn-primary"
              style={{ 
                display: 'block', 
                width: '250px', 
                margin: '20px auto', 
                padding: '10px 10px', 
                fontSize: '0.9em'
              }}
            >
              Save details
            </button>
          </form>
            )}
        </div>
      </div>
      </PageLayout>
    </>
  );
};

export default YourComponent;
