import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import './AdvertiserStep1PageLayout.css';

const AdvertiserStep1PageLayout = ({ children }) => {
  return (
    <div style={{ backgroundColor: 'white' }} className="advertiser-step1-layout d-flex flex-column min-vh-100">
      <Navbar />
      <div className="d-flex flex-grow-1">
        <Sidebar pageType="advertiserstep1" />
        <div className="advertiser-step1-content flex-grow-1 px-2 overflow-hidden">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdvertiserStep1PageLayout; 