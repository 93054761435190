import React from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../../../config/constants';
import SendNotification from '../modals/SendNotification';

const NotificationSender = () => {
    const token = localStorage.getItem('token');

    const handleSend = async (notificationData) => {
        try {
            const response = await axios.post(
                `${BASE_API_URL}api/AdminPanel/SendNotificationToAllUsers`,
                notificationData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            if (response.status === 200) {
                alert('Notification sent successfully!');
            }
        } catch (error) {
            console.error('Error sending notification:', error);
            alert('Failed to send notification. Please try again.');
        }
    };

    return (
        <div className="notification-sender-container p-4 bg-white rounded shadow-sm">
            <SendNotification 
                show={true} 
                onClose={() => {}} 
                onSend={handleSend}
                standalone={true}
            />
        </div>
    );
};

export default NotificationSender; 