import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PaymentConfirmation.css';
import PageLayout from './PageLayout';

const PaymentConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { amount, products } = location.state || {};

  useEffect(() => {
    // Redirect if no state is present
    if (!location.state || !amount || !products) {
       navigate('/');
    }
  }, [location.state, amount, products, navigate]);

  // Return null while checking state or if there's no valid state
  if (!location.state || !amount || !products) {
     return null;
  }

  const handleContinue = () => {
    navigate('/your-orders');
  };

  return (
    <PageLayout pageType="payment-confirmation">
      <div className="payment-confirmation-container">
        <div className="payment-confirmation-card">
          <div className="payment-confirmation-header">
            <div className="payment-confirmation-success-icon">✓</div>
            <div className="payment-confirmation-header-text">
              <h1>Payment Successful!</h1>
            </div>
          </div>
          
          <p>Your order has been confirmed!</p>
          <p className="payment-confirmation-next-steps">
            You can track your order status in the "Your Orders" section
          </p>
          <button className="payment-confirmation-continue-button" onClick={handleContinue}>
            View Your Orders
          </button>
          
          <div className="payment-confirmation-order-details">
            <h3>Order Summary:</h3>
            <p>Total Amount: ${amount}</p>
            {products && products.length > 0 && (
              <div className="payment-confirmation-products-list">
                <h4>Purchased Links:</h4>
                <ul>
                  {products.map((product, index) => (
                    <li key={index}>
                      <div className="payment-confirmation-link-item">
                        <strong>Keyword:</strong> {product.keyword}
                        <br />
                        <strong>Publisher:</strong> {product.publisherWebsite}
                        <br />
                        <strong>Monthly Value:</strong> ${product.monthlyValue}
                        <br />
                        <strong>DA:</strong> {product.domainAuthority}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PaymentConfirmation; 