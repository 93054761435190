import React from 'react';

const MySpinner = ({ small }) => (
  <div className={`spinner-border text-primary ${small ? 'spinner-small' : ''}`} role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

export default MySpinner;



