import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import targetUrlImg from './assets/images/targeturl.png';
import MySpinner from './components/MySpinner';
import { Alert } from 'react-bootstrap';
import './KeywordsReports.css';

const KeywordsReports = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    keyword: '',
    url: '',
    title: '',
    status: 'all',
    monthlyValue: '',
    linkType: 'all',
    exclusivity: 'all',
    domainAuthority: '',
    publisherWebsite: '',
    creationDate: '',
  });
  const [expandedCard, setExpandedCard] = useState(null);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${BASE_API_URL}api/linksreports/get-keyword-reports-for-advertiser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setKeywords(response.data);
        setFilteredKeywords(response.data);
      } catch (error) {
        console.error('Full error:', error);
        setError('Failed to fetch keywords reports.');
        console.error('Error fetching keywords reports:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchKeywords();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    let result = keywords;

    if (filters.keyword) {
      result = result.filter(item => 
        item.keyword.toLowerCase().includes(filters.keyword.toLowerCase())
      );
    }

    if (filters.url) {
      result = result.filter(item => 
        item.url.toLowerCase().includes(filters.url.toLowerCase())
      );
    }

    if (filters.title) {
      result = result.filter(item => 
        item.title.toLowerCase().includes(filters.title.toLowerCase())
      );
    }

    if (filters.status !== 'all') {
      result = result.filter(item => 
        item.isActive === (filters.status === 'active')
      );
    }

    if (filters.monthlyValue) {
      result = result.filter(item => 
        item.monthlyValue <= parseFloat(filters.monthlyValue)
      );
    }

    if (filters.linkType !== 'all') {
      result = result.filter(item => 
        console.log('Comparing:', item.typeOfLink, filters.linkType) ||
        item.typeOfLink && item.typeOfLink.toLowerCase() === filters.linkType.toLowerCase()
      );
    }

    if (filters.exclusivity !== 'all') {
      result = result.filter(item => 
        item.exclusivity === (filters.exclusivity === 'yes')
      );
    }

    if (filters.domainAuthority) {
      result = result.filter(item => 
        item.domainAuthority >= parseInt(filters.domainAuthority)
      );
    }

    if (filters.publisherWebsite) {
      result = result.filter(item => 
        item.publisherWebsite.toLowerCase().includes(filters.publisherWebsite.toLowerCase())
      );
    }

    if (filters.creationDate) {
      result = result.filter(item => 
        new Date(item.dateCreated).toISOString().split('T')[0] === filters.creationDate
      );
    }

    setFilteredKeywords(result);
  }, [filters, keywords]);

  const toggleExpandCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  return (
    <PageLayout pageType="keywordsreports">
      <div className="keywords-reports-container">
        <h5>Keywords Reports</h5>

        <div className="keywords-reports-filters">
          <div className="keywords-reports-filters-row">
            <input
              type="text"
              name="keyword"
              placeholder="Filter by keyword"
              value={filters.keyword}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
            <input
              type="text"
              name="url"
              placeholder="Filter by URL"
              value={filters.url}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
            <input
              type="text"
              name="title"
              placeholder="Filter by title"
              value={filters.title}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="keywords-reports-filter-select"
            >
              <option value="all">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <div className="keywords-reports-filters-row">
            <input
              type="number"
              name="monthlyValue"
              placeholder="Max monthly value"
              value={filters.monthlyValue}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
            <select
              name="linkType"
              value={filters.linkType}
              onChange={handleFilterChange}
              className="keywords-reports-filter-select"
            >
              <option value="all">All Link Types</option>
              <option value="normalfollow">NormalFollow</option>
              <option value="nofollow">NoFollow</option>
              <option value="ugc">UGC</option>
              <option value="sponsored">Sponsored</option>
            </select>
            <select
              name="exclusivity"
              value={filters.exclusivity}
              onChange={handleFilterChange}
              className="keywords-reports-filter-select"
            >
              <option value="all">All Exclusivity</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <input
              type="number"
              name="domainAuthority"
              placeholder="Min domain authority"
              value={filters.domainAuthority}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
          </div>

          <div className="keywords-reports-filters-row">
            <input
              type="text"
              name="publisherWebsite"
              placeholder="Filter by publisher website"
              value={filters.publisherWebsite}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
            <input
              type="date"
              name="creationDate"
              value={filters.creationDate}
              onChange={handleFilterChange}
              className="keywords-reports-filter-input"
            />
          </div>
        </div>

        {error && (
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" onClose={() => setSuccess('')} dismissible>
            {success}
          </Alert>
        )}

        {isLoading ? (
          <MySpinner />
        ) : (
          <div className="keywords-reports-card-container">
            {filteredKeywords.length === 0 ? (
              <p>No keywords reports available.</p>
            ) : (
              filteredKeywords.map((item, index) => (
                <div 
                  className="keywords-reports-card" 
                  key={index}
                  onClick={() => toggleExpandCard(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="keywords-reports-card-body">
                    <div className="keywords-reports-card-header">
                      <div className="keywords-reports-header-left">
                        <h5 className="keywords-reports-card-title">
                          {item.keyword}
                          <span className="keywords-reports-title">({item.title})</span>
                          {item.targetUrl && (
                            <a 
                              href={item.targetUrl} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="keywords-reports-target-icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img 
                                src={targetUrlImg} 
                                alt="Target url" 
                                title={item.targetUrl}
                              />
                            </a>
                          )}
                          <span className="keywords-reports-link-type">{item.typeOfLink}</span>
                          <span className="keywords-reports-publisher">{item.publisherWebsite}</span>
                          <span className="keywords-reports-date">{item.dateCreated}</span>
                        </h5>
                      </div>
                      <div className="keywords-reports-header-right">
                        <span className="keywords-reports-monthly-value">${item.monthlyValue}</span>
                        <span className={`keywords-reports-status ${
                          item.isActive ? 'keywords-reports-status-active' : 'keywords-reports-status-inactive'
                        }`}>
                          {item.isActive ? 'Active' : 'Inactive'}
                        </span>
                        <span className="keywords-reports-expand-icon">
                          {expandedCard === index ? '−' : '+'}
                        </span>
                      </div>
                    </div>
                    
                    {expandedCard === index && (
                      <div 
                        className="keywords-reports-card-details"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="keywords-reports-info-group">
                          <p className="keywords-reports-card-text">
                            <strong>URL:</strong> 
                            <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
                          </p>
                          <p className="keywords-reports-card-text">
                            <strong>Exclusivity:</strong> {item.exclusivity ? 'Yes' : 'No'}
                          </p>
                          <p className="keywords-reports-card-text">
                            <strong>Domain Authority:</strong> {item.domainAuthority}
                          </p>
                          <p className="keywords-reports-card-text">
                            <strong>Occurrences:</strong> {item.occurences}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default KeywordsReports;
