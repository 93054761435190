import React from 'react';
import CheckoutForm from './CheckoutForm';
import StripePayment from './components/shared/StripePayment';
  

const Checkout = ({ amount, products }) => {
  const subscriptionEndpoints = {
    create: 'api/checkout/create-subscription',
    update: 'api/checkout/update-payment-method'
  };

  const handleSuccess = (type, data) => {
    if (type === 'payment_created') {
      console.log('Subscription created successfully:', data);
    } else if (type === 'payment_method_updated') {
      console.log('Payment method updated successfully:', data);
    }
  };

  const handleError = (error) => {
    console.error('Payment error:', error);
  };

  return (
    <StripePayment
      amount={amount}
      products={products}
      paymentType="subscription"
      endpoints={subscriptionEndpoints}
      CheckoutFormComponent={CheckoutForm}
      onSuccess={handleSuccess}
      onError={handleError}
    />
  );
};

export default Checkout;