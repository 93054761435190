import React, { useEffect, useState } from 'react';
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner,
    Container,
    Row,
    Col
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import { BASE_API_URL } from '../src/config/constants';
import './AdvertiserStep1.css';
import AdvertiserStep1PageLayout from './components/AdvertiserStep1PageLayout';
import PageLayout from './components/PageLayout';

const AdvertiserStep1 = () => {
    const [data, setData] = useState([]);
    const [totalPrices, setTotalPrices] = useState({});
    const [filters, setFilters] = useState({
        domainName: '',
        theme: '',
        country: '',
        typeOfPortal: '',
        region: '',
        minUniqueUsers: '',
        dofollowLinks: false,
        typeOfLinks: '',
        numberOfLinks: '',
        priceFrom: '',
        priceTo: '',
        attractivePrice: false,
        domainAuthority: '',
        portalQuality: '',
        favorites: false,
    });
    const [uniqueCountries, setUniqueCountries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
    // Add loading state
    const [loading, setLoading] = useState(true);

    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });

    const fetchData = async (filters) => {
        setLoading(true); // Set loading to true
        try {
            const response = await fetch(`${BASE_API_URL}api/UserDomains`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filters)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);

            // Extract unique countries from data
            const countries = [...new Set(jsonData.map(entry => entry.country))];
            setUniqueCountries(countries);

            // Fetch total prices for all entries
            jsonData.forEach(entry => fetchTotalPrice(entry));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };

    const fetchTotalPrice = async (entry) => {
        const url = `${BASE_API_URL}api/pricecalculator/calculate?domainAuthority=${entry.domainAuthority}&exclusivity=false&linkType=NormalFollow&price=${entry.price}`;
        try {
            const response = await fetch(url);
            const result = await response.json();
            setTotalPrices(prev => ({ ...prev, [entry.domain]: result.basePrice }));
        } catch (error) {
            console.error('Error fetching total price:', error);
        }
    };

    useEffect(() => {
        fetchData(filters);
    }, [filters]);

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters({ ...filters, [name]: checked });
    };

    const handleChooseClick = (entry) => {
        setSelectedEntry(entry);
        setModalVisible(true);
    };

    const handleSearch = () => {
        fetchData(filters);
        setCurrentPage(1);
    }

    const ArrowSpan = () => {
        return (
            <span className='row h-25'>
                <FontAwesomeIcon icon={faCaretUp} size='m' style={{ color: "black" }} />
                <FontAwesomeIcon icon={faCaretDown} size='m' style={{ color: "black" }} />
            </span>
        );
    };

    const handlePageChange = (pageNumber) => {
        console.log(`Changing page to: ${pageNumber}`);
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    console.log(`Current Page: ${currentPage}, Items per Page: ${itemsPerPage}`);
    console.log(`Index of First Item: ${indexOfFirstItem}, Index of Last Item: ${indexOfLastItem}`);
    console.log(`Current Items: ${currentItems.length}`);

    const priceStyle = {
        fontSize: '1.25rem'
    };

    const handleSort = (columnIndex) => {
        console.log('Sorting column:', columnIndex);
        setSortConfig(prevConfig => {
            const isSameColumn = prevConfig.key === columnIndex;
            const newDirection = isSameColumn && prevConfig.direction === 'asc' ? 'desc' : 'asc';
            console.log('New sort config:', { key: columnIndex, direction: newDirection });
            return {
                key: columnIndex,
                direction: newDirection
            };
        });
    };

    const getSortedItems = (items) => {
        if (!sortConfig.key && sortConfig.key !== 0) return items;

        return [...items].sort((a, b) => {
            let aValue, bValue;

            switch (sortConfig.key) {
                case 0: // Portal
                    aValue = String(a.domain || '').toLowerCase();
                    bValue = String(b.domain || '').toLowerCase();
                    break;
                case 1: // Unique Users
                    aValue = parseInt(String(a.uniqueUsers || '0').replace(/[^0-9]/g, '')) || 0;
                    bValue = parseInt(String(b.uniqueUsers || '0').replace(/[^0-9]/g, '')) || 0;
                    break;
                case 2: // Dofollow
                    aValue = a.dofollow ? 1 : 0;
                    bValue = b.dofollow ? 1 : 0;
                    break;
                case 3: // Traffic Tracking
                    aValue = a.trafficTracking ? 1 : 0;
                    bValue = b.trafficTracking ? 1 : 0;
                    break;
                case 4: // Domain Authority
                    aValue = parseInt(a.domainAuthority) || 0;
                    bValue = parseInt(b.domainAuthority) || 0;
                    break;
                case 5: // Price
                    aValue = parseFloat(totalPrices[a.domain]) || 0;
                    bValue = parseFloat(totalPrices[b.domain]) || 0;
                    break;
                default:
                    return 0;
            }

            const compareResult = typeof aValue === 'string'
                ? aValue.localeCompare(bValue)
                : aValue - bValue;

            return sortConfig.direction === 'asc' ? compareResult : -compareResult;
        });
    };

    const renderSortableHeader = (title, index) => (
        <div 
            className={`header-content ${
                sortConfig.key === index 
                    ? sortConfig.direction === 'asc'
                        ? 'sort-asc'
                        : 'sort-desc'
                    : ''
            }`} 
            onClick={(e) => {
                e.stopPropagation();
                handleSort(index);
            }}
        >
            {title}
        </div>
    );

    return (
        <PageLayout>
            <div className="advertiser-step1-container">
                <Row className="g-3">
                    <Col xs={12}>
                        <div className="header">
                            <h5 className='mx-3'>Advertiser</h5>
                        </div>
                        <p className='mx-3'>
                            (you will be able to buy links in already existing native articles - the most valuable ones)
                        </p>
                        <h5 className='mt-4 mx-3'>Choose a publication</h5>
                        <div className='inputDiv'>
                            <h5>Basic filters</h5>
                            <div className="form-group2">
                                <div className="input-row">
                                    <input
                                        name='domainAuthority'
                                        placeholder='Domain Authority'
                                        type="text"
                                        value={filters.domainAuthority}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name='domainName'
                                        placeholder='Domain website name, tag'
                                        type="text"
                                        value={filters.domainName}
                                        onChange={handleInputChange}
                                    />
                                    <select
                                        name='country'
                                        value={filters.country}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Country</option>
                                        {uniqueCountries.map((country, index) => (
                                            <option key={index} value={country}>{country}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-row">
                                    <input
                                        name='minUniqueUsers'
                                        placeholder='Min. unique users'
                                        type="text"
                                        value={filters.minUniqueUsers}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name='priceFrom'
                                        placeholder='Price from'
                                        type="text"
                                        value={filters.priceFrom}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name='priceTo'
                                        placeholder='Price to'
                                        type="text"
                                        value={filters.priceTo}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="search-button-container">
                                    <button 
                                        onClick={handleSearch} 
                                        type="button" 
                                        className="btn btn-primary search-button"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='adverTable'>
                            {data.length > itemsPerPage && !loading && (
                                <div className="pagination-dropdown mb-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <button 
                                            className="btn btn-outline-primary"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        
                                        <select 
                                            className="pagination-select"
                                            value={currentPage}
                                            onChange={(e) => handlePageChange(Number(e.target.value))}
                                        >
                                            {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
                                                <option key={index + 1} value={index + 1}>
                                                    Page {index + 1} of {Math.ceil(data.length / itemsPerPage)}
                                                </option>
                                            ))}
                                        </select>
                                        
                                        <button 
                                            className="btn btn-outline-primary"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="table-responsive overflow-visible table-wrapper">
                                {loading ? (
                                    <div className="spinner-container">
                                        <Spinner style={{ width: '3rem', height: '3rem', color: 'blue' }} />
                                    </div>
                                ) : (
                                    <>
                                        <div className="desktop-view">
                                            <Table hover bordered responsive className="advertiser-table">
                                                <thead style={{ verticalAlign: 'middle' }}>
                                                    <tr>
                                                        <th scope="col">
                                                            <div className="column-content">
                                                                {renderSortableHeader('Portal', 0)}
                                                                <span className='d-flex flex-column'>
                                                                    <small style={{ fontWeight: 'lighter' }}>
                                                                        Type <br />
                                                                        Country: Region<br />
                                                                        Theme
                                                                    </small>
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Unique Users', 1)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Dofollow', 2)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Traffic Tracking', 3)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Domain Authority', 4)}
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="column-content">
                                                                {renderSortableHeader('Price / Month', 5)}
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getSortedItems(currentItems).map((entry, index) => (
                                                        <tr key={index}>
                                                            <td className='border-right align-middle portal-cell-advertiser'>
                                                                <div className="portal-content-advertiser">
                                                                    <h5>{entry.domain}</h5>
                                                                    <div className="portal-details-advertiser">
                                                                        <small>{entry.websiteType}</small>
                                                                        <small>{entry.country}</small>
                                                                        <small>{entry.theme}</small>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <small>{entry.uniqueUsers}</small>
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <FontAwesomeIcon icon={entry.dofollow ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.dofollow ? "red" : "#21c442", marginRight: '3px' }} />
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <FontAwesomeIcon icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.trafficTracking ? "#21c442" : "red", marginRight: '3px' }} />
                                                            </td>
                                                            <td className='border align-middle text-center'>
                                                                <small>{entry.domainAuthority}</small>
                                                            </td>
                                                            <td className='border-left align-middle text-center'>
                                                                <small style={priceStyle} className='me-2'>
                                                                    {totalPrices[entry.domain] >= 0
                                                                        ? `${totalPrices[entry.domain]}$`
                                                                        : 'Loading...'} /month
                                                                </small>
                                                                <button 
                                                                    className='mt-0 btn btn-primary btn-sm btn-add' 
                                                                    onClick={() => handleChooseClick(entry)}
                                                                >
                                                                    Add
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div className="mobile-view">
                                            {getSortedItems(currentItems).map((entry, index) => (
                                                <div key={index} className="advertiser-mobile-card">
                                                    <div className="advertiser-mobile-header">
                                                        <h3 className="advertiser-mobile-title">{entry.domain}</h3>
                                                    </div>
                                                    
                                                    <div className="advertiser-mobile-details">
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Type</span>
                                                            <span className="advertiser-mobile-value">{entry.websiteType}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Country</span>
                                                            <span className="advertiser-mobile-value">{entry.country}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Theme</span>
                                                            <span className="advertiser-mobile-value">{entry.theme}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Unique Users</span>
                                                            <span className="advertiser-mobile-value">{entry.uniqueUsers}</span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Dofollow</span>
                                                            <span className="advertiser-mobile-value">
                                                                <FontAwesomeIcon 
                                                                    icon={entry.dofollow ? faCircleCheck : faCircleXmark} 
                                                                    style={{ color: entry.dofollow ? "red" : "#21c442" }} 
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Traffic Tracking</span>
                                                            <span className="advertiser-mobile-value">
                                                                <FontAwesomeIcon 
                                                                    icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} 
                                                                    style={{ color: entry.trafficTracking ? "#21c442" : "red" }} 
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="advertiser-mobile-detail-row">
                                                            <span className="advertiser-mobile-label">Domain Authority</span>
                                                            <span className="advertiser-mobile-value">{entry.domainAuthority}</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="advertiser-mobile-price">
                                                        <span className="advertiser-mobile-price-value">
                                                            {totalPrices[entry.domain] >= 0
                                                                ? `${totalPrices[entry.domain]}$ /month`
                                                                : 'Loading...'}
                                                        </span>
                                                        <button 
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => handleChooseClick(entry)}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="text-end mt-2" style={{ paddingRight: '98px', fontSize: '1.50rem' }}>
                            {!loading && <small><em>*prices without VAT</em></small>}
                        </div>
                    </Col>
                </Row>
            </div>
            {modalVisible && <Modal entry={selectedEntry} onClose={() => setModalVisible(false)} />}
        </PageLayout>
    );
}

export default AdvertiserStep1;
