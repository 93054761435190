import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLink, 
  faLayerGroup, 
  faClipboardList, 
  faChartLine,
  faUser,
  faWallet,
  faCreditCard,
  faFileInvoice,
  faBuilding,
  faPlus,
  faChevronDown,
  faChevronRight,
  faDownload,
  faQuestionCircle,
  faSignOutAlt,
  faBell,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../authService';

const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType; // Assuming 'userType' is the custom claim you set
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

const getIsActiveFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.isActive;
  } catch (error) {
    return 0;
  }
}

const Sidebar = ({ pageType }) => {
  const token = localStorage.getItem('token');
  const userType = getUserTypeFromToken(token);  
  const isActive = token ? getIsActiveFromToken(token) : 0;
  const [isBillingSubMenuVisible, setBillingSubMenuVisible] = useState(false);
  const navigate = useNavigate();

  const toggleBillingSubMenu = (e) => {
    e.preventDefault(); // Add this to prevent # appearing in URL
    setBillingSubMenuVisible(!isBillingSubMenuVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div className="sidebar-sections-wrapper">
          {userType === 'advertiser' && (
            <div className="sidebar-section">
              <h6 className="sidebar-heading">My Keywords</h6>
              <ul className="sidebar-menu">
                <li><a onClick={() => navigate('/Advertiser-step1')}>
                  <FontAwesomeIcon icon={faLink} /> Order links
                </a></li>
                <li><a onClick={() => navigate('/bulkorder1')}>
                  <FontAwesomeIcon icon={faLayerGroup} /> Bulk Order links
                </a></li>
                <li><a onClick={() => navigate('/your-orders')}>
                  <FontAwesomeIcon icon={faClipboardList} /> Your orders
                </a></li>
                <li><a onClick={() => navigate('/KeywordsReports')}>
                  <FontAwesomeIcon icon={faChartLine} /> Keywords reports
                </a></li>
              </ul>
            </div>
          )}

          {userType === 'publisher' && (
            <div className="sidebar-section">
              <h6 className="sidebar-heading">My Portals</h6>
              <ul className="sidebar-menu">
                <li><a onClick={() => navigate('/publisher')}>
                  <FontAwesomeIcon icon={faPlus} /> Add Website
                </a></li>
                <li><a onClick={() => navigate('/publisher-step2')}>
                  <FontAwesomeIcon icon={faBuilding} /> Websites
                </a></li>
                <li><a onClick={() => navigate('/LinksReports')}>
                  <FontAwesomeIcon icon={faChartLine} /> Links reports
                </a></li>
              </ul>
            </div>
          )}

          {userType === 'sysadmin' && (
            <div className="sidebar-section">
              <h6 className="sidebar-heading">Admin Controls</h6>
              <ul className="sidebar-menu">
                <li>
                  <a onClick={() => navigate('/admin')}>
                    <FontAwesomeIcon icon={faUser} /> Admin Panel
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div className="sidebar-section">
            <h6 className="sidebar-heading">My Account</h6>
            <ul className="sidebar-menu">
              <li>
                <a onClick={() => navigate('/MyAccount')}>
                  <FontAwesomeIcon icon={faUser} /> My account
                </a>
              </li>
              <li>
                <a onClick={toggleBillingSubMenu} className="submenu-trigger">
                  <FontAwesomeIcon icon={faCreditCard} />
                  <span style={{ flex: 1 }}>Billing</span>
                  <FontAwesomeIcon 
                    icon={isBillingSubMenuVisible ? faChevronDown : faChevronRight} 
                    className="submenu-arrow"
                  />
                </a>
                {isBillingSubMenuVisible && (
                  <ul className="sidebar-submenu">
                    {userType === 'advertiser' && (
                      <>
                        <li><a onClick={() => navigate('/Subscriptions')}>
                          <FontAwesomeIcon icon={faLayerGroup} /> Subscriptions
                        </a></li>
                        <li><a onClick={() => navigate('/Wallet')}>
                          <FontAwesomeIcon icon={faWallet} /> Wallet
                        </a></li>
                      </>
                    )}
                    <li><a onClick={() => navigate('/company-details')}>
                      <FontAwesomeIcon icon={faBuilding} /> Billing details
                    </a></li>
                    <li><a onClick={() => navigate(userType === 'advertiser' ? '/Invoices' : '/PublisherInvoices')}>
                      <FontAwesomeIcon icon={faFileInvoice} /> Invoices
                    </a></li>
                  </ul>
                )}
              </li>
            </ul>
          </div>

          {userType === 'publisher' && (
            <div className="sidebar-section">
              <h6 className="sidebar-heading">WordPress Plugin</h6>
              <ul className="sidebar-menu">
                <li><a onClick={() => navigate('/Download')}>
                  <FontAwesomeIcon icon={faDownload} /> Download
                </a></li>
                <li><a onClick={() => navigate('/HowToUseIt')}>
                  <FontAwesomeIcon icon={faQuestionCircle} /> How to use it
                </a></li>
              </ul>
            </div>
          )}
        </div>

        <div className="sidebar-section logout-section">
          <ul className="sidebar-menu">
            <li>
              <a onClick={handleLogout} className="logout-button">
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
