import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faShoppingCart, faWallet, faBars } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../authService";
import { useCart } from "../CartContext";
import NotificationDropdown from "./NotificationDropdown";
import { useNotifications } from "./NotificationContext";
import './Navbar.css';

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType; // Assuming 'userType' is a claim in the token
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const Navbar = ({ toggleSidebar }) => {
  const { setIsLoggedIn, setToken, setUserType } = useAuth();
  const { notifications, setNotifications } = useNotifications();
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const token = localStorage.getItem('token');
  const userName = getUserName(token);
  const userTypeFromToken = getUserTypeFromToken(token);
  const cartItemCount = cartItems ? cartItems.length : 0;
  const unreadCount = notifications.filter(notification => !notification.isRead).length;

  const handleCartClick = () => {
    navigate('/cart');
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <nav className="navbar">
      <div className="container-fluid">
        {/* Left side */}
        <div className="navbar-start">
          <button 
            className="icon-button"
            onClick={toggleSidebar}
            aria-label="Toggle menu"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          
          <a className="navbar-brand" onClick={(e) => {
            e.preventDefault();
            navigate(userTypeFromToken === 'advertiser' ? "/advertiser" : "/publisher");
          }}>
            <img 
              src={require("../assets/images/Link-Building-Software.png")} 
              alt="AdverLink"
            />
          </a>
        </div>
        
        {/* Right side */}
        <div className="navbar-end">
          {userTypeFromToken === 'advertiser' && (
            <button 
              className="icon-button" 
              onClick={handleCartClick}
              aria-label="Shopping cart"
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              {cartItemCount > 0 && <span className="badge">{cartItemCount}</span>}
            </button>
          )}
          
          <div className="username-display">
            <span>{userName}</span>
          </div>
          
          <div className="notification-container">
            <button 
              className="icon-button"
              onClick={handleNotificationClick}
              aria-label="Notifications"
            >
              <FontAwesomeIcon icon={faBell} />
              {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
            </button>
            
            {isNotificationsOpen && (
              <NotificationDropdown 
                notifications={notifications}
                setNotifications={setNotifications}
                isOpen={isNotificationsOpen}
                setIsOpen={setIsNotificationsOpen}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
