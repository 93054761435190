// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advertiser-step1-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}

.advertiser-step1-content {
  flex: 1 1;
  padding: 20px;
  background-color: #fff;
}

/* Add a container for the content with white background */
.advertiser-step1-container {
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
}

/* Keep the navbar background color separate */
.navbar {
  background-color: #f8f9fa;
}

/* Custom scrollbar styles */
.advertiser-step1-content::-webkit-scrollbar {
  width: 8px;
}

.advertiser-step1-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.advertiser-step1-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.advertiser-step1-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ensure content doesn't overflow horizontally */
.advertiser-step1-content > .container-fluid {
  max-width: 100%;
  height: 100%;
}

/* Add some spacing between elements */
.advertiser-step1-content .row {
  margin-bottom: 1rem;
}

/* Ensure tables don't cause horizontal scrolling */
.advertiser-step1-content .table-responsive {
  margin-bottom: 1rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
} `, "",{"version":3,"sources":["webpack://./src/components/AdvertiserStep1PageLayout.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA,0DAA0D;AAC1D;EACE,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA,8CAA8C;AAC9C;EACE,yBAAyB;AAC3B;;AAEA,4BAA4B;AAC5B;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,iDAAiD;AACjD;EACE,eAAe;EACf,YAAY;AACd;;AAEA,sCAAsC;AACtC;EACE,mBAAmB;AACrB;;AAEA,mDAAmD;AACnD;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iCAAiC;AACnC","sourcesContent":[".advertiser-step1-layout {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  background-color: #f8f9fa;\n}\n\n.advertiser-step1-content {\n  flex: 1;\n  padding: 20px;\n  background-color: #fff;\n}\n\n/* Add a container for the content with white background */\n.advertiser-step1-container {\n  background-color: #fff;\n  border-radius: 8px;\n  height: 100%;\n}\n\n/* Keep the navbar background color separate */\n.navbar {\n  background-color: #f8f9fa;\n}\n\n/* Custom scrollbar styles */\n.advertiser-step1-content::-webkit-scrollbar {\n  width: 8px;\n}\n\n.advertiser-step1-content::-webkit-scrollbar-track {\n  background: #f1f1f1;\n  border-radius: 4px;\n}\n\n.advertiser-step1-content::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 4px;\n}\n\n.advertiser-step1-content::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n/* Ensure content doesn't overflow horizontally */\n.advertiser-step1-content > .container-fluid {\n  max-width: 100%;\n  height: 100%;\n}\n\n/* Add some spacing between elements */\n.advertiser-step1-content .row {\n  margin-bottom: 1rem;\n}\n\n/* Ensure tables don't cause horizontal scrolling */\n.advertiser-step1-content .table-responsive {\n  margin-bottom: 1rem;\n  overflow-x: auto;\n  -webkit-overflow-scrolling: touch;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
