import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import './BulkOrderPageLayout.css';

const BulkOrderPageLayout = ({ children }) => {
  return (
    <div style={{ backgroundColor: 'white' }} className="bulk-order-layout d-flex flex-column min-vh-100">
      <Navbar />
      <div className="d-flex flex-grow-1">
        <Sidebar pageType="bulkorder" />
        <div className="bulk-order-content flex-grow-1 p-0 overflow-hidden">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BulkOrderPageLayout; 