// PublishersList.jsx
import React, { useState } from 'react';
import { Eye, Edit2, Ban } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const StatCard = ({ title, value, subtext, trend }) => (
  <div className="card shadow-sm">
    <div className="card-body p-4">
      <h6 className="text-muted text-sm mb-2">{title}</h6>
      <h3 className="mb-1">{value}</h3>
      <small className={`text-${trend?.type || 'muted'}`}>{subtext}</small>
    </div>
  </div>
);

export default function PublishersList({ showHeader = true }) {
  const [selectedPublishers, setSelectedPublishers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    country: 'all',
    domainRating: 'all'
  });
  const navigate = useNavigate();

  // Mock data (unchanged)
  const stats = {
    totalPublishers: {
      count: 1234,
      growth: 15
    },
    activeDomains: {
      count: 987,
      growth: 8
    },
    pendingApprovals: {
      count: 45,
      newToday: 12
    },
    revenueShare: {
      amount: 45678,
      growth: 12
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedPublishers(['all']);
    } else {
      setSelectedPublishers([]);
    }
  };

  const handleSelectPublisher = (publisherId) => {
    setSelectedPublishers(prev => {
      if (prev.includes(publisherId)) {
        return prev.filter(id => id !== publisherId);
      }
      return [...prev, publisherId];
    });
  };

  const handleShowDetails = (publisher) => {
    navigate(`/publishers/${publisher.id}`, { state: { publisher } });
  };

  return (
    <div className="p-0">
      {/* Search and Filters */}
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <div className="row g-3 align-items-center">
            <div className="col-md-3">
              <input 
                type="text" 
                className="form-control"
                placeholder="Search publishers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.status}
                onChange={(e) => setFilters({...filters, status: e.target.value})}
              >
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.country}
                onChange={(e) => setFilters({...filters, country: e.target.value})}
              >
                <option value="all">All Countries</option>
                <option value="us">United States</option>
                <option value="uk">United Kingdom</option>
                <option value="ca">Canada</option>
              </select>
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.domainRating}
                onChange={(e) => setFilters({...filters, domainRating: e.target.value})}
              >
                <option value="all">Domain Rating</option>
                <option value="50">DR 50+</option>
                <option value="60">DR 60+</option>
                <option value="70">DR 70+</option>
              </select>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100">
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Statistics Cards */}
      <div className="row g-4 mb-4">
        <div className="col-md-3">
          <StatCard
            title="Total Publishers"
            value={stats.totalPublishers.count}
            subtext={`+${stats.totalPublishers.growth}% this month`}
            trend={{ type: 'success' }}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Active Domains"
            value={stats.activeDomains.count}
            subtext={`+${stats.activeDomains.growth}% this month`}
            trend={{ type: 'success' }}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Pending Approvals"
            value={stats.pendingApprovals.count}
            subtext={`${stats.pendingApprovals.newToday} new today`}
            trend={{ type: 'warning' }}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Total Revenue Share"
            value={`$${stats.revenueShare.amount.toLocaleString()}`}
            subtext={`+${stats.revenueShare.growth}% this month`}
            trend={{ type: 'success' }}
          />
        </div>
      </div>

      {/* Publishers Table */}
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover align-middle">
              <thead>
                <tr>
                  <th width="40">
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      onChange={handleSelectAll}
                      checked={selectedPublishers.includes('all')}
                    />
                  </th>
                  <th>Publisher</th>
                  <th>Domains</th>
                  <th>Avg Domain Rating</th>
                  <th>Total Traffic</th>
                  <th>Active Links</th>
                  <th>Revenue</th>
                  <th>Status</th>
                  <th width="120">Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* Example Publisher Row */}
                <tr>
                  <td>
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      checked={selectedPublishers.includes('1')}
                      onChange={() => handleSelectPublisher('1')}
                    />
                  </td>
                  <td>
                    <div>
                      <div className="fw-medium">Jane Smith</div>
                      <small className="text-muted">jane@example.com</small>
                    </div>
                  </td>
                  <td>
                    <div>156 Domains</div>
                    <small className="text-success">142 Active</small>
                  </td>
                  <td>
                    <div>DR 65</div>
                    <small className="text-muted">Range: 45-80</small>
                  </td>
                  <td>
                    <div>2.5M</div>
                    <small className="text-success">+12% MoM</small>
                  </td>
                  <td>
                    <div>234</div>
                    <small className="text-muted">Across all domains</small>
                  </td>
                  <td>
                    <div>$4,567</div>
                    <small className="text-success">This month</small>
                  </td>
                  <td><span className="badge bg-success">Active</span></td>
                  <td>
                    <div className="d-flex gap-2">
                      <button 
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => handleShowDetails({
                          id: 'PUB-12345',
                          name: 'Jane Smith',
                          email: 'jane@example.com',
                          mode: 'view'
                        })}
                        title="View Details"
                      >
                        <Eye className="w-4 h-4" />
                      </button>
                      <button 
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => handleShowDetails({
                          id: 'PUB-12345',
                          name: 'Jane Smith',
                          email: 'jane@example.com',
                          mode: 'edit'
                        })}
                        title="Edit Publisher"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                      <button 
                        className="btn btn-sm btn-outline-danger"
                        title="Suspend Publisher"
                      >
                        <Ban className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Add more publisher rows as needed */}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p className="text-muted mb-0">Showing 1-10 of 1,234 publishers</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
