// SubscriptionPanel.jsx
import React, { useEffect, useState } from 'react';
import { BASE_API_URL } from '../src/config/constants';
import PageLayout from './components/PageLayout';
import Modal from './Modal';
import './SubscriptionPanel.css';
import SubscriptionPanelModal from './SubscriptionPanelModal';
import ProductRemovalModal from './SubscriptiopnPanel/ProductRemovealModal';
import SubscriptionPanelPageLayout from './SubscriptiopnPanel/SubscriptionPanelPageLayout';
import MySpinner from './components/MySpinner';

const SubscriptionPanel = () => {
    const token = localStorage.getItem('token');
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('all');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
    const [productToRemove, setProductToRemove] = useState(null);
    const [isProductRemovalModalOpen, setIsProductRemovalModalOpen] = useState(false);
    const [paymentType, setPaymentType] = useState('1');

    useEffect(() => {
        fetchAllSubscriptions();
    }, [token]);

    const fetchAllSubscriptions = async () => {
        setLoading(true);
        try {
            // Fetch cash subscriptions
            const [cashActiveResponse, cashCanceledResponse] = await Promise.all([
                fetch(`${BASE_API_URL}api/Order/get-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
                fetch(`${BASE_API_URL}api/Order/get-canceled-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                })
            ]);

            // Fetch wallet subscriptions
            const [walletActiveResponse, walletCanceledResponse] = await Promise.all([
                fetch(`${BASE_API_URL}api/Order/get-wallet-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
                fetch(`${BASE_API_URL}api/Order/get-canceled-wallet-subscriptions`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                })
            ]);

            // Process cash subscriptions
            let cashActive = [];
            let cashCanceled = [];
            if (cashActiveResponse.ok) {
                cashActive = await cashActiveResponse.json();
            }
            if (cashCanceledResponse.ok && cashCanceledResponse.status !== 204) {
                cashCanceled = await cashCanceledResponse.json();
            }

            // Process wallet subscriptions
            let walletActive = [];
            let walletCanceled = [];
            if (walletActiveResponse.ok) {
                walletActive = await walletActiveResponse.json();
            }
            if (walletCanceledResponse.ok && walletCanceledResponse.status !== 204) {
                walletCanceled = await walletCanceledResponse.json();
            }

            // Combine all subscriptions with their respective types
            const allSubs = [
                ...cashActive.map(sub => ({ ...sub, status: 'active', expanded: false, paymentType: '1' })),
                ...cashCanceled.map(sub => ({ ...sub, status: 'canceled', expanded: false, paymentType: '1' })),
                ...walletActive.map(sub => ({ ...sub, status: 'active', expanded: false, paymentType: '2' })),
                ...walletCanceled.map(sub => ({ ...sub, status: 'canceled', expanded: false, paymentType: '2' }))
            ];

            setAllSubscriptions(allSubs);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
            setError('Failed to fetch subscriptions. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleExpand = (subscriptionId) => {
        setAllSubscriptions(prevSubscriptions =>
            prevSubscriptions.map(subscription =>
                subscription.id === subscriptionId
                    ? { ...subscription, expanded: !subscription.expanded }
                    : { ...subscription, expanded: false }
            )
        );
    };

    const handleOpenModal = (subscriptionId) => {
        setSubscriptionToCancel(subscriptionId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSubscriptionToCancel(null);
    };

    const handleConfirmCancel = async () => {
        try {
            // Find the subscription to get its payment type
            const subscriptionToRemove = allSubscriptions.find(sub => sub.id === subscriptionToCancel);
            
            // Determine which endpoint to use based on payment type
            const endpoint = subscriptionToRemove?.paymentType === '2' 
                ? `${BASE_API_URL}api/Order/remove-wallet-subscription/${subscriptionToCancel}`
                : `${BASE_API_URL}api/Order/remove-subscription/${subscriptionToCancel}`;

            const response = await fetch(endpoint, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to remove subscription');
            }

            await fetchAllSubscriptions();
            handleCloseModal();
        } catch (error) {
            console.error('Error removing subscription:', error);
            setError('Failed to remove subscription. Please try again later.');
        }
    };

    const handleRemoveProduct = async (id, subscriptionId, productId, productName) => {
        setProductToRemove({
            id, // if wallet
            subscriptionId,
            productId,
            name: productName
        });
        setIsProductRemovalModalOpen(true);
    };

    const handleConfirmProductRemoval = async () => {
        try {

            const subscriptionToRemove = allSubscriptions.find(sub => sub.id === subscriptionToCancel);
            
            const endpoint = subscriptionToRemove?.paymentType === '2' 
            ? `${BASE_API_URL}api/Order/remove-wallet-product/${productToRemove.id}`
            : `${BASE_API_URL}api/Order/remove-product/${productToRemove.subscriptionId}/${productToRemove.productId}`;

            const response = await fetch(
                `${BASE_API_URL}api/Order/remove-product/${productToRemove.subscriptionId}/${productToRemove.productId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to remove product');
            }

            await fetchAllSubscriptions();
            setIsProductRemovalModalOpen(false);
            setProductToRemove(null);
        } catch (error) {
            console.error('Error removing product:', error);
            setError('Failed to remove product. Please try again later.');
        }
    };

    const handleCloseProductRemovalModal = () => {
        setIsProductRemovalModalOpen(false);
        setProductToRemove(null);
    };

    const filteredSubscriptions = allSubscriptions.filter(sub => {
        const statusMatch = filter === 'all' || sub.status === filter;
        const paymentTypeMatch = sub.paymentType === paymentType;
        return statusMatch && paymentTypeMatch;
    });

    return (
        <PageLayout>
            <div style={{'padding-left': '20px'}}>
                <h5>Manage Subscriptions</h5>
                <div className="payment-segmented-control">
                    {/* Radio for Card */}
                    <input
                        type="radio"
                        name="paymentType"
                        id="payment-card"
                        value="1"
                        checked={paymentType === '1'}
                        onChange={(e) => setPaymentType(e.target.value)}
                    />
                    {/* Radio for Wallet */}
                    <input
                        type="radio"
                        name="paymentType"
                        id="payment-wallet"
                        value="2"
                        checked={paymentType === '2'}
                        onChange={(e) => setPaymentType(e.target.value)}
                    />

                    {/* The container that holds the labels and the slider */}
                    <div className="switch-background">
                        <label htmlFor="payment-card" className="segmented-label">Card</label>
                        <label htmlFor="payment-wallet" className="segmented-label">Wallet</label>
                        {/* This span is our moving slider */}
                        <span className="slider"></span>
                    </div>
                </div>


                <div className="filter-buttons">
                    <button onClick={() => setFilter('all')} className={filter === 'all' ? 'active' : ''}>All</button>
                    <button onClick={() => setFilter('active')} className={filter === 'active' ? 'active' : ''}>Active</button>
                    <button onClick={() => setFilter('canceled')} className={filter === 'canceled' ? 'active' : ''}>Canceled</button>
                </div>
                {loading ? (
                    <MySpinner />
                ) : error ? (
                    <p className="error">{error}</p>
                ) : (
                    <div className="subscription-grid">
                        {filteredSubscriptions.map((subscription) => (
                            <div
                                key={subscription.id}
                                className={`subscription-item ${subscription.expanded ? 'expanded' : ''} ${subscription.status}`}
                                onClick={() => handleExpand(subscription.id)}
                            >
                                <div className="subscription-header">
                                    <span className="price">${subscription.amount} USD</span>
                                    <span className={`status ${subscription.status}`}>{subscription.status}</span>
                                </div>
                                <div className="subscription-dates">
                                    {new Date(subscription.startDate).toLocaleDateString()} - {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                                </div>
                                {subscription.status === 'active' && (
                                    <button onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenModal(subscription.id);
                                    }} className="cancel-button">
                                        Cancel Subscription
                                    </button>
                                )}
                                {subscription.expanded && (
                                    <ul className="product-list">
                                        {subscription.products.map((product) => (
                                            <li key={product.id}>
                                                <span>{product.name} on {product.platform}</span>
                                                {subscription.status === 'active' && (
                                                    <button 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveProduct(
                                                                product.id, // if wallet
                                                                subscription.id, 
                                                                product.productId,
                                                                product.name
                                                            );
                                                        }} 
                                                        className="remove-button"
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <SubscriptionPanelModal 
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal} 
                    onConfirm={handleConfirmCancel} 
                    message="Are you sure you want to cancel this order?" 
                />
                <ProductRemovalModal
                    isOpen={isProductRemovalModalOpen}
                    onClose={handleCloseProductRemovalModal}
                    onConfirm={handleConfirmProductRemoval}
                    productName={productToRemove?.name}
                />
            </div>
        </PageLayout>
    );
};

export default SubscriptionPanel;