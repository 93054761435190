// AdvertiserList.jsx
import React, { useState } from 'react';
import { Eye, Edit2, Ban, Search, Filter, Building, TrendingUp, CreditCard, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const StatCard = ({ title, value, subtext, trend, icon: Icon }) => (
  <div className="card shadow-sm">
    <div className="card-body p-4">
      <div className="d-flex justify-content-between align-items-start mb-2">
        <h6 className="text-muted text-sm mb-0">{title}</h6>
        {Icon && <Icon className="text-muted" size={20} />}
      </div>
      <h3 className="mb-1">{value}</h3>
      <small className={`text-${trend?.type || 'muted'}`}>{subtext}</small>
    </div>
  </div>
);

export default function AdvertiserList() {
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    industry: 'all',
    budget: 'all'
  });
  const navigate = useNavigate();

  // Mock data
  const stats = {
    totalAdvertisers: {
      count: 856,
      growth: 12
    },
    activeSpend: {
      amount: 234567,
      growth: 15
    },
    avgCampaignValue: {
      amount: 3450,
      growth: 8
    },
    conversionRate: {
      value: '68%',
      growth: 5
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedAdvertisers(['all']);
    } else {
      setSelectedAdvertisers([]);
    }
  };

  const handleSelectAdvertiser = (advertiserId) => {
    setSelectedAdvertisers(prev => {
      if (prev.includes(advertiserId)) {
        return prev.filter(id => id !== advertiserId);
      }
      return [...prev, advertiserId];
    });
  };

  const handleShowDetails = (advertiser) => {
    navigate(`/advertisers/${advertiser.id}`, { state: { advertiser } });
  };

  return (
    <div className=" p-0">
      {/* Search and Filters */}
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <div className="row g-3 align-items-center">
            <div className="col-md-3">
              <div className="input-group">
                <span className="input-group-text bg-white border-end-0">
                  <Search size={18} className="text-muted" />
                </span>
                <input 
                  type="text" 
                  className="form-control border-start-0"
                  placeholder="Search advertisers..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.status}
                onChange={(e) => setFilters({...filters, status: e.target.value})}
              >
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="paused">Paused</option>
                <option value="blocked">Blocked</option>
              </select>
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.industry}
                onChange={(e) => setFilters({...filters, industry: e.target.value})}
              >
                <option value="all">All Industries</option>
                <option value="tech">Technology</option>
                <option value="finance">Finance</option>
                <option value="health">Healthcare</option>
                <option value="retail">Retail</option>
              </select>
            </div>
            <div className="col-md-2">
              <select 
                className="form-select"
                value={filters.budget}
                onChange={(e) => setFilters({...filters, budget: e.target.value})}
              >
                <option value="all">All Budgets</option>
                <option value="10000">$10k+</option>
                <option value="50000">$50k+</option>
                <option value="100000">$100k+</option>
              </select>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100">
                <Filter size={18} className="me-2" />
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Statistics Cards */}
      <div className="row g-4 mb-4">
        <div className="col-md-3">
          <StatCard
            title="Total Advertisers"
            value={stats.totalAdvertisers.count}
            subtext={`+${stats.totalAdvertisers.growth}% this month`}
            trend={{ type: 'success' }}
            icon={Users}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Active Monthly Spend"
            value={`$${stats.activeSpend.amount.toLocaleString()}`}
            subtext={`+${stats.activeSpend.growth}% vs last month`}
            trend={{ type: 'success' }}
            icon={CreditCard}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Avg Campaign Value"
            value={`$${stats.avgCampaignValue.amount}`}
            subtext={`+${stats.avgCampaignValue.growth}% this month`}
            trend={{ type: 'success' }}
            icon={Building}
          />
        </div>
        <div className="col-md-3">
          <StatCard
            title="Conversion Rate"
            value={stats.conversionRate.value}
            subtext={`+${stats.conversionRate.growth}% vs last month`}
            trend={{ type: 'success' }}
            icon={TrendingUp}
          />
        </div>
      </div>

      {/* Advertisers Table */}
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover align-middle">
              <thead>
                <tr>
                  <th width="40">
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      onChange={handleSelectAll}
                      checked={selectedAdvertisers.includes('all')}
                    />
                  </th>
                  <th>Advertiser</th>
                  <th>Industry</th>
                  <th>Monthly Budget</th>
                  <th>Active Campaigns</th>
                  <th>Total Spent</th>
                  <th>Conversion Rate</th>
                  <th>Status</th>
                  <th width="120">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      checked={selectedAdvertisers.includes('1')}
                      onChange={() => handleSelectAdvertiser('1')}
                    />
                  </td>
                  <td>
                    <div>
                      <div className="fw-medium">TechCorp Solutions</div>
                      <small className="text-muted">john@techcorp.com</small>
                    </div>
                  </td>
                  <td>
                    <div>Technology</div>
                    <small className="text-muted">SaaS</small>
                  </td>
                  <td>
                    <div>$50,000</div>
                    <small className="text-success">+15% from last month</small>
                  </td>
                  <td>
                    <div>12</div>
                    <small className="text-success">8 running</small>
                  </td>
                  <td>
                    <div>$234,567</div>
                    <small className="text-success">Lifetime value</small>
                  </td>
                  <td>
                    <div>68%</div>
                    <small className="text-success">+5% MoM</small>
                  </td>
                  <td><span className="badge bg-success">Active</span></td>
                  <td>
                    <div className="d-flex gap-2">
                      <button 
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => handleShowDetails({
                          id: 'ADV-12345',
                          name: 'TechCorp Solutions',
                          email: 'john@techcorp.com',
                          industry: 'Technology',
                          subIndustry: 'SaaS',
                          mode: 'view'
                        })}
                        title="View Details"
                      >
                        <Eye className="w-4 h-4" />
                      </button>
                      <button 
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => handleShowDetails({
                          id: 'ADV-12345',
                          name: 'TechCorp Solutions',
                          email: 'john@techcorp.com',
                          industry: 'Technology',
                          subIndustry: 'SaaS',
                          mode: 'edit'
                        })}
                        title="Edit Advertiser"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                      <button 
                        className="btn btn-sm btn-outline-danger"
                        title="Block Advertiser"
                      >
                        <Ban className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Add more advertiser rows as needed */}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p className="text-muted mb-0">Showing 1-10 of 856 advertisers</p>
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
