import React, { useState, useRef } from 'react';
import { Col } from 'react-bootstrap';

const DomainAuthorityRangeSlider = ({ 
  maxDomainAuthority = 100,
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  errorMessage,
  setErrorMessage
}) => {
  const sliderRef = useRef(null);
  const [activeThumb, setActiveThumb] = useState(null);

  // Ensure values are within bounds
  const boundValues = (min, max) => {
    return [
      Math.max(0, Math.min(min, maxDomainAuthority)),
      Math.min(maxDomainAuthority, Math.max(max, min))
    ];
  };

  // Calculate percentage position
  const calculatePosition = (value) => {
    return `${(value / maxDomainAuthority) * 100}%`;
  };

  // Handle mouse/touch start on thumb
  const startDragging = (thumb) => (e) => {
    e.preventDefault();
    setActiveThumb(thumb);

    // Prevent text selection during drag
    document.body.style.userSelect = 'none';

    const moveHandler = (moveEvent) => {
      if (!sliderRef.current) return;

      const slider = sliderRef.current;
      const rect = slider.getBoundingClientRect();

      // Calculate value based on cursor position
      const clientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const percentage = ((clientX - rect.left) / rect.width) * 100;
      const newValue = Math.round((percentage / 100) * maxDomainAuthority);

      if (thumb === 'min') {
        const boundedMin = Math.max(0, Math.min(newValue, maxValue));
        setMinValue(boundedMin);

        if (boundedMin > maxValue) {
          setErrorMessage('Minimum value cannot be greater than maximum');
        } else {
          setErrorMessage('');
        }
      } else if (thumb === 'max') {
        const boundedMax = Math.min(maxDomainAuthority, Math.max(newValue, minValue));
        setMaxValue(boundedMax);

        if (boundedMax < minValue) {
          setErrorMessage('Maximum value cannot be less than minimum');
        } else {
          setErrorMessage('');
        }
      }
    };

    const stopDragging = () => {
      setActiveThumb(null);
      document.body.style.userSelect = '';
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchmove', moveHandler);
      document.removeEventListener('touchend', stopDragging);
    };

    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('touchmove', moveHandler);
    document.addEventListener('touchend', stopDragging);
  };

  // Handle click on the slider
  const handleClick = (e) => {
    if (!sliderRef.current) return;

    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();

    // Calculate value based on click position
    const clickX = e.clientX;
    const percentage = ((clickX - rect.left) / rect.width) * 100;
    const clickedValue = Math.round((percentage / 100) * maxDomainAuthority);

    // Determine which thumb is closer
    const minDiff = Math.abs(clickedValue - minValue);
    const maxDiff = Math.abs(clickedValue - maxValue);

    if (minDiff < maxDiff) {
      // Move the minimum thumb
      const boundedMin = Math.max(0, Math.min(clickedValue, maxValue));
      setMinValue(boundedMin);

      if (boundedMin > maxValue) {
        setErrorMessage('Minimum value cannot be greater than maximum');
      } else {
        setErrorMessage('');
      }
    } else {
      // Move the maximum thumb
      const boundedMax = Math.min(maxDomainAuthority, Math.max(clickedValue, minValue));
      setMaxValue(boundedMax);

      if (boundedMax < minValue) {
        setErrorMessage('Maximum value cannot be less than minimum');
      } else {
        setErrorMessage('');
      }
    }
  };

  return (
    <Col xs={12} md={4}>
      <div 
        ref={sliderRef}
        onClick={handleClick}
        style={{
          width: '100%',
          height: '10px',
          backgroundColor: '#e9ecef',
          borderRadius: '5px',
          position: 'relative',
          cursor: 'pointer'
        }}
      >
        {/* Filled Track */}
        <div 
          style={{
            position: 'absolute',
            height: '100%',
            left: calculatePosition(minValue),
            width: calculatePosition(maxValue - minValue),
            backgroundColor: '#007bff',
            borderRadius: '5px',
            zIndex: 1
          }}
        />

        {/* Minimum Thumb */}
        <div 
          onMouseDown={startDragging('min')}
          onTouchStart={startDragging('min')}
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#007bff',
            borderRadius: '50%',
            position: 'absolute',
            top: '-5px',
            left: calculatePosition(minValue),
            transform: 'translateX(-50%)',
            cursor: 'grab',
            zIndex: 2
          }}
        />

        {/* Maximum Thumb */}
        <div 
          onMouseDown={startDragging('max')}
          onTouchStart={startDragging('max')}
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#007bff',
            borderRadius: '50%',
            position: 'absolute',
            top: '-5px',
            left: calculatePosition(maxValue),
            transform: 'translateX(-50%)',
            cursor: 'grab',
            zIndex: 2
          }}
        />
      </div>

      <div className="d-flex justify-content-between mt-2">
        <small>Min: {minValue}</small>
        <small className="text-end" style={{marginLeft: 'auto'}}>Max: {maxValue}</small>
      </div>

      {errorMessage && (
        <div className="text-danger mt-2">{errorMessage}</div>
      )}
    </Col>
  );
};

export default DomainAuthorityRangeSlider;