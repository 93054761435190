import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MyLineChart from './components/LineChart';
import {
    Table,
    Badge,
    UncontrolledDropdown,
    DropdownItem,
    Input
} from "reactstrap";
import { useAuth } from './authService';
import { Link, useNavigate } from 'react-router-dom';
import DomainKeyModal from './DomainKeyModal';  // Import the modal component
import { BASE_API_URL } from './config/constants';
import PageLayout from './components/PageLayout';
import MySpinner from './components/MySpinner';
import './PublisherStep2.css';

const tableStyles = {
    responsiveTable: {
        width: '100%',
        margin: '0 auto',
        borderSpacing: '0',
    },
    tableWrapper: {
        width: '100%',
        overflowX: 'auto',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        marginBottom: '2rem'
    },
    cell: {
        padding: '12px 16px',
        textAlign: 'left',
        borderBottom: '1px solid #e0e0e0',
    },
    headerCell: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    }
};

const PublisherStep2 = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [secretKeys, setSecretKeys] = useState({}); // To store secret keys
    const [secretKeyWasGenerated, setSecretKeyWasGenerated] = useState(false); //TODO: maybe better way

    // Edit modal state
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedDomainData, setSelectedDomainData] = useState(null);

    // Delete modal state
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedDomainId, setSelectedDomainId] = useState(null);

    const fetchData = async () => {
        setLoading(true); // Start loading
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }
    
            const domainResponse = await axios.get(`${BASE_API_URL}api/userdomains/publisher-get`, {           
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const domains = domainResponse.data;
    
            const keyResponse = await axios.get(`${BASE_API_URL}api/userdomains/get-secret-keys`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const keys = keyResponse.data;
    
            const keyMap = keys.reduce((acc, keyObj) => {
                acc[keyObj.domain] = keyObj.secretKey;
                return acc;
            }, {});
    
            const parsedData = domains.map(item => ({
                ...item,
                secretKey: keyMap[item.domain] || 'N/A'
            }));
            setData(parsedData);
            setSecretKeys(keyMap);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Please try again later.'); // User feedback
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchData();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate, secretKeyWasGenerated]);

    const toggleModal = (domain = '') => {
        setSelectedDomain(domain);
        setModalOpen(!modalOpen);
    };

    // const toggleEditModal = (domainId) => {
    //     console.log(`edit/${domainId}`);
    //     if (domainId) {
    //         navigate(`edit/${domainId}`); // Navigate to edit page with the domainId
    //     }
    // };

    const toggleDeleteModal = (domainId = null) => {
        setSelectedDomainId(domainId);
        setDeleteModalOpen(!deleteModalOpen);
    };

    const ActionButton = ({ domain, domainId }) => {
        return (
            <div className="publisherstep2-actions">
                <button 
                    className="publisherstep2-action-btn generate-btn"
                    onClick={() => toggleModal(domain)}
                >
                    Generate key
                </button>
                <Link 
                    to={`/edit/${domainId}`} 
                    className="publisherstep2-action-btn edit-btn"
                >
                    Edit
                </Link>
            </div>
        );
    };

    return (
        <>
        <PageLayout pageType='publisherstep2'>
            <div className='publisherstep2-container'>
                <div className="main2">
                    <div className="publisherstep2-header">
                        <h5>Publisher</h5>
                    </div>
                    <p className="publisherstep2-description">
                        (website owner, blog owner, corporate websites, personal websites)
                    </p>
                    {loading ? (
                        <MySpinner/>
                    ) : data.length > 0 ? (
                        <>
                            <div className='bg-primary publisherstep2-label text-white'>Websites</div>
                            <div className="publisherstep2-table-wrapper">
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th style={{...tableStyles.cell, ...tableStyles.headerCell}}>#</th>
                                            <th style={{...tableStyles.cell, ...tableStyles.headerCell}}>Website</th>
                                            <th style={{...tableStyles.cell, ...tableStyles.headerCell}}>Monthly Revenue</th>
                                            <th style={{...tableStyles.cell, ...tableStyles.headerCell}}>Secret key</th>
                                            <th style={{...tableStyles.cell, ...tableStyles.headerCell}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td style={tableStyles.cell} data-label="#">{index + 1}</td>
                                                <td style={tableStyles.cell} data-label="Website">{item.domain}</td>
                                                <td style={tableStyles.cell} data-label="Monthly Revenue">
                                                    {item.price} USD + VAT
                                                </td>
                                                <td style={tableStyles.cell} data-label="Secret key">
                                                    <div className="publisherstep2-secret-key-container">
                                                        <Input
                                                            type="text"
                                                            value={item.secretKey}
                                                            readOnly
                                                            className="publisherstep2-secret-key"
                                                            onClick={() => navigator.clipboard.writeText(item.secretKey)}
                                                            title="Click to copy"
                                                        />
                                                        <small
                                                            className="publisherstep2-copy-text"
                                                            onClick={() => navigator.clipboard.writeText(item.secretKey)}
                                                        >
                                                            Copy
                                                        </small>
                                                    </div>
                                                </td>
                                                <td style={tableStyles.cell} data-label="Action">
                                                    <ActionButton domain={item.domain} domainId={item.id}/>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <div className="text-center my-5">
                            <p>No data found.</p>
                        </div>
                    )}
                </div>
            </div>
            <DomainKeyModal isOpen={modalOpen} toggle={toggleModal} domain={selectedDomain} setWasGenerated={setSecretKeyWasGenerated} />
        </PageLayout>
        </>
    );
};

export default PublisherStep2;