import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate } from 'react-router-dom';
import PageLayout from './components/PageLayout';
import './YourOrders.css';

const YourOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderNumber: '',
    keyword: '',
    publisherWebsite: '',
    minTotal: '',
    maxTotal: '',
    typeOfLink: '',
    domainAuthority: ''
  });
  const [publisherWebsites, setPublisherWebsites] = useState([]);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/Order/get`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOrders(response.data);
      return response.data;
    } catch (err) {
      setError('Failed to fetch order data');
      console.error('Error fetching orders:', err);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const ordersData = await fetchOrders();
      const websites = getUniquePublisherWebsites(ordersData);
      setPublisherWebsites(websites);
    };
    
    loadData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const filteredOrders = orders.filter(order => {
    return (
      (!filters.orderNumber || order.id.toString().includes(filters.orderNumber)) &&
      (!filters.keyword || order.wordToLinks.some(link => 
        link.keyword.toLowerCase().includes(filters.keyword.toLowerCase())
      )) &&
      (!filters.publisherWebsite || order.wordToLinks.some(link =>
        link.publisherWebsite.toLowerCase().includes(filters.publisherWebsite.toLowerCase())
      )) &&
      (!filters.minTotal || calculateTotal(order.wordToLinks) >= Number(filters.minTotal)) &&
      (!filters.maxTotal || calculateTotal(order.wordToLinks) <= Number(filters.maxTotal)) &&
      (!filters.typeOfLink || order.wordToLinks.some(link =>
        link.typeOfLink.toLowerCase().includes(filters.typeOfLink.toLowerCase())
      )) &&
      (!filters.domainAuthority || order.wordToLinks.some(link =>
        link.domainAuthority.toString().includes(filters.domainAuthority)
      ))
    );
  });

  return (
    <PageLayout pageType='your-orders'>
      <div className="your-orders-container">
        <div className="your-orders-header">
          <h5>Your Orders</h5>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>
        </div>

        <div className="your-orders-filters">
          <h5>Filters</h5>
          <div className="your-orders-filters-grid">
            <input
              type="text"
              name="orderNumber"
              placeholder="Order Number"
              value={filters.orderNumber}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              name="keyword"
              placeholder="Keyword"
              value={filters.keyword}
              onChange={handleFilterChange}
            />
            <select
              name="publisherWebsite"
              value={filters.publisherWebsite}
              onChange={handleFilterChange}
              className="your-orders-select"
            >
              <option value="">All Publisher Websites</option>
              {publisherWebsites.map((website, index) => (
                <option key={index} value={website}>
                  {website}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="typeOfLink"
              placeholder="Type of Link"
              value={filters.typeOfLink}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="domainAuthority"
              placeholder="Domain Authority"
              value={filters.domainAuthority}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="minTotal"
              placeholder="Min Total"
              value={filters.minTotal}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxTotal"
              placeholder="Max Total"
              value={filters.maxTotal}
              onChange={handleFilterChange}
            />
          </div>
        </div>

        <h5>Order Summary (LaaS)</h5>

        {isLoading ? (
          <div className="your-orders-loading">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div className="your-orders-error">Error: {error}</div>
        ) : filteredOrders.length === 0 ? (
          <p className="your-orders-empty">You have no orders at the moment.</p>
        ) : (
          <div className="your-orders-list">
            {filteredOrders.map((order) => (
              <div key={order.id} className="your-orders-card">
                <h3>Order number: {order.id}</h3>
                <div className="your-orders-table-wrapper">
                  <table className="your-orders-table">
                    <thead>
                      <tr>
                        <th>Keyword</th>
                        <th>Monthly Value</th>
                        <th>Type of Link</th>
                        <th>Publisher Website</th>
                        <th>Domain Authority</th>
                        <th>Exclusivity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.wordToLinks.map((item, index) => (
                        <tr key={index}>
                          <td data-label="Keyword">
                            <div className="your-orders-keyword">
                              {item.keyword}
                            </div>
                          </td>
                          <td data-label="Monthly Value">${item.monthlyValue}</td>
                          <td data-label="Type of Link">{item.typeOfLink}</td>
                          <td data-label="Publisher Website">{item.publisherWebsite}</td>
                          <td data-label="Domain Authority">{item.domainAuthority}</td>
                          <td data-label="Exclusivity">{item.exclusivity ? 'Yes' : 'No'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="your-orders-total">
                  <strong>Total: ${calculateTotal(order.wordToLinks)}</strong>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

const calculateTotal = (wordToLinks) => {
  if (!wordToLinks) return 0;
  return wordToLinks.reduce((total, item) => total + item.monthlyValue, 0);
};

const getUniquePublisherWebsites = (orders) => {
  if (!orders || !Array.isArray(orders)) return [];
  
  const websites = new Set();
  orders.forEach(order => {
    if (order.wordToLinks && Array.isArray(order.wordToLinks)) {
      order.wordToLinks.forEach(link => {
        if (link.publisherWebsite) {
          websites.add(link.publisherWebsite);
        }
      });
    }
  });
  return Array.from(websites).sort();
};

export default YourOrders;
