import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../../../config/constants';
import { Modal, Button } from 'react-bootstrap';
import { Search, X, Mail } from 'lucide-react';
import toast from 'react-hot-toast';

const EmailTemplatesManager = () => {
    const defaultEmailTemplate = `<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>AdverLink Email</title>
</head>
<body style='font-family: Arial, sans-serif; line-height: 1.6; color: #333;'>




<h3>Need Assistance?</h3>
<p>Our support team is here to help! Feel free to reach out to us with any questions at 
<a href='mailto:support@adverlink.net'>support@adverlink.net</a></p>

<p>Best regards,<br>
The AdverLink Team</p>

<div style='margin-top: 30px;'>
    <img src='https://adverlinkapi.azurewebsites.net/images/adverlink-logo.png' 
            alt='Adverlink Logo' 
            style='width:150px; height:auto;'>
    <p>
        Phone: +40722237373<br>
        Email: <a href='mailto:support@adverlink.net'>support@adverlink.net</a>
    </p>
</div>
</body>
</html>`;

    const [emailContent, setEmailContent] = useState(defaultEmailTemplate);
    const [users, setUsers] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const token = localStorage.getItem('token');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState({ publishers: [], advertisers: [] });
    const [emailSubject, setEmailSubject] = useState('');
    const [userDomains, setUserDomains] = useState([]);
    const [filters, setFilters] = useState({
        publisherDomains: 'all',
        customDomainsMin: '0',
        customDomainsMax: '100',
        specificDomain: 'all',
        domainAuthority: 'all',
        customDomainAuthorityMin: '0',
        customDomainAuthorityMax: '100'
    });
    const [showDetailedList, setShowDetailedList] = useState(false);
    const [showFooterModal, setShowFooterModal] = useState(false);

    useEffect(() => {
        fetchUsers();
        fetchUserDomains();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}api/User/AdminPanel`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchUserDomains = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}api/AdminPanel/GetUserDomains`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserDomains(response.data);
        } catch (error) {
            console.error('Error fetching user domains:', error);
        }
    };

    useEffect(() => {
        const getDomainCount = (userId) => {
            return userDomains.filter(domain => domain.userId === userId).length;
        };

        const filterPublishersByDomains = (publishers) => {
            return publishers.filter(publisher => {
                const publisherDomains = userDomains
                    .filter(domain => domain.userId === publisher.userId);
                
                // Search term should match email or any domain
                if (searchTerm.trim()) {
                    const emailMatch = publisher.email.toLowerCase().includes(searchTerm.toLowerCase().trim());
                    const domainMatch = publisherDomains.some(d => 
                        d.domain.toLowerCase().includes(searchTerm.toLowerCase().trim())
                    );
                    if (!emailMatch && !domainMatch) return false;
                }

                // Domain filter
                if (filters.specificDomain !== 'all') {
                    const hasSelectedDomain = publisherDomains.some(d => 
                        d.domain === filters.specificDomain
                    );
                    if (!hasSelectedDomain) return false;
                }

                // Domain Authority filter
                if (filters.domainAuthority === 'custom') {
                    if (filters.customDomainAuthorityMin && filters.customDomainAuthorityMax) {
                        const min = parseInt(filters.customDomainAuthorityMin);
                        const max = parseInt(filters.customDomainAuthorityMax);
                        const hasMatchingAuthority = publisherDomains.some(domain => {
                            const authority = domain.domainAuthority || 0;
                            return authority >= min && authority <= max;
                        });
                        if (!hasMatchingAuthority) return false;
                    }
                } else if (filters.domainAuthority !== 'all') {
                    const hasMatchingAuthority = publisherDomains.some(domain => {
                        const authority = domain.domainAuthority || 0;
                        switch (filters.domainAuthority) {
                            case '0-30':
                                return authority >= 0 && authority <= 30;
                            case '31-60':
                                return authority >= 31 && authority <= 60;
                            case '61-100':
                                return authority >= 61 && authority <= 100;
                            default:
                                return true;
                        }
                    });
                    if (!hasMatchingAuthority) return false;
                }

                // Domain Count filter
                if (filters.publisherDomains === 'custom') {
                    const domainCount = getDomainCount(publisher.userId);
                    const min = parseInt(filters.customDomainsMin);
                    const max = parseInt(filters.customDomainsMax);
                    if (domainCount < min || domainCount > max) return false;
                } else if (filters.publisherDomains !== 'all') {
                    const domainCount = getDomainCount(publisher.userId);
                    switch (filters.publisherDomains) {
                        case '1-5':
                            return domainCount >= 1 && domainCount <= 5;
                        case '6-10':
                            return domainCount >= 6 && domainCount <= 10;
                        case '11+':
                            return domainCount > 10;
                        default:
                            return true;
                    }
                }

                return true;
            });
        };

        const filtered = {
            publishers: filterPublishersByDomains(
                users.filter(user => user.userType === 'publisher')
            ),
            advertisers: users.filter(user => 
                user.userType === 'advertiser' && 
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            )
        };
        setFilteredUsers(filtered);
    }, [
        searchTerm, 
        users, 
        userDomains, 
        filters.publisherDomains, 
        filters.specificDomain, 
        filters.domainAuthority,
        filters.customDomainAuthorityMin,
        filters.customDomainAuthorityMax,
        filters.customDomainsMin,
        filters.customDomainsMax
    ]);

    const toggleRecipient = (recipient) => {
        setSelectedRecipients(prev => {
            const isSelected = prev.find(r => r.userId === recipient.userId);
            if (isSelected) {
                return prev.filter(r => r.userId !== recipient.userId);
            }
            return [...prev, recipient];
        });
    };

    const handleSelectAll = (group) => {
        const groupUsers = users.filter(user => user.userType === group);
        setSelectedRecipients(prevRecipients => {
            const currentIds = prevRecipients.map(r => r.userId);
            const allSelected = groupUsers.every(user => currentIds.includes(user.userId));
            
            if (allSelected) {
                return prevRecipients.filter(r => r.userType !== group);
            } else {
                const newRecipients = [...prevRecipients];
                groupUsers.forEach(user => {
                    if (!currentIds.includes(user.userId)) {
                        newRecipients.push(user);
                    }
                });
                return newRecipients;
            }
        });
    };

    const handleClearAll = () => {
        setSearchTerm('');
        setSelectedRecipients([]);
        setFilters(prev => ({
            ...prev,
            publisherDomains: 'all',
            customDomainsMin: '0',
            customDomainsMax: getMaxDomainCount().toString(),
            specificDomain: 'all',
            domainAuthority: 'all',
            customDomainAuthorityMin: '0',
            customDomainAuthorityMax: '100'
        }));
    };

    const handleSendEmail = async () => {
        try {
            const loadingToast = toast.loading('Sending emails...', {
                position: 'top-right'
            });

            const payload = {
                userIDs: selectedRecipients.map(r => r.userId),
                subject: emailSubject,
                html: emailContent
            };
            
            await axios.post(`${BASE_API_URL}api/AdminPanel/SendEmails`, payload, {
                headers: { Authorization: `Bearer ${token}` }
            });

            toast.dismiss(loadingToast);
            toast.success('Emails sent successfully!', {
                duration: 4000,
                position: 'top-right',
                style: {
                    background: '#10B981',
                    color: 'white',
                },
                icon: '📧'
            });

            setShowConfirmModal(false);
            setEmailContent(defaultEmailTemplate);
            setEmailSubject('');
            setSelectedRecipients([]);
        } catch (error) {
            toast.error(`Failed to send emails: ${error.response?.data || 'Please try again.'}`, {
                duration: 5000,
                position: 'top-right',
                style: {
                    background: '#EF4444',
                    color: 'white',
                },
                icon: '❌'
            });
            console.error('Error sending email:', error);
        }
    };

    const getRecipientText = () => {
        if (selectedRecipients.length === 0) {
            return <span className="text-muted">No recipients selected</span>;
        }

        const publisherCount = selectedRecipients.filter(r => r.userType === 'publisher').length;
        const advertiserCount = selectedRecipients.filter(r => r.userType === 'advertiser').length;

                return (
            <div>
                <div className="mb-2">
                    <strong>Total Recipients:</strong> {selectedRecipients.length}
                </div>
                <div className="mb-2">
                    <strong>Publishers:</strong> {publisherCount}
                    <br />
                    <strong>Advertisers:</strong> {advertiserCount}
                </div>

            </div>
        );
    };

    const getUniqueDomains = () => {
        return [...new Set(userDomains.map(d => d.domain))].sort();
    };

    const getMaxDomainCount = () => {
        const userDomainCounts = users
            .filter(user => user.userType === 'publisher')
            .map(user => userDomains.filter(domain => domain.userId === user.userId).length);
        return Math.max(...userDomainCounts, 1);
    };

    useEffect(() => {
        if (users.length > 0 && userDomains.length > 0) {
            const maxDomains = getMaxDomainCount();
            setFilters(prev => ({
                ...prev,
                customDomainsMax: maxDomains.toString()
            }));
        }
    }, [users, userDomains]);

    // Add this function to get active filters text
    const getActiveFiltersText = () => {
        const activeFilters = [];

        if (filters.publisherDomains === 'custom') {
            activeFilters.push(`Domains Count: ${filters.customDomainsMin} - ${filters.customDomainsMax}`);
        } else if (filters.publisherDomains !== 'all') {
            activeFilters.push(`Domains Count: ${filters.publisherDomains}`);
        }

        if (filters.specificDomain !== 'all') {
            activeFilters.push(`Specific Domain: ${filters.specificDomain}`);
        }

        if (filters.domainAuthority === 'custom') {
            activeFilters.push(`Domain Authority: ${filters.customDomainAuthorityMin} - ${filters.customDomainAuthorityMax}`);
        } else if (filters.domainAuthority !== 'all') {
            activeFilters.push(`Domain Authority: ${filters.domainAuthority}`);
        }

        if (searchTerm) {
            activeFilters.push(`Search: "${searchTerm}"`);
        }

        return activeFilters;
    };

    const getDetailedRecipientInfo = (recipient) => {
        if (recipient.userType === 'publisher') {
            const publisherDomains = userDomains
                .filter(domain => domain.userId === recipient.userId)
                .map(domain => ({
                    domain: domain.domain,
                    authority: domain.domainAuthority || 0
                }));
            
            return (
                <div key={recipient.userId} className="mb-3 p-2 border-bottom">
                    <div><strong>Email:</strong> {recipient.email}</div>
                    <div><strong>Type:</strong> {recipient.userType}</div>
                    <div>
                        <strong>Domains:</strong>
                        <ul className="mb-0 mt-1">
                            {publisherDomains.map((d, index) => (
                                <li key={index}>
                                    {d.domain} (DA: {d.authority})
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            );
        }
        
        return (
            <div key={recipient.userId} className="mb-3 p-2 border-bottom">
                <div><strong>Email:</strong> {recipient.email}</div>
                <div><strong>Type:</strong> {recipient.userType}</div>
            </div>
        );
    };

    const emailFooterTemplate = `
        <h3>Need Assistance?</h3>
        <p>Our support team is here to help! Feel free to reach out to us with any questions at 
        <a href='mailto:support@adverlink.net'>support@adverlink.net</a></p>
        
        <p>Thank you for choosing AdverLink! We're excited to support your authority-building journey.</p>
        
        <p>Best regards,<br>
        The AdverLink Team</p>
        
        <div style='margin-top: 30px;'>
            <img src='https://adverlinkapi.azurewebsites.net/images/adverlink-logo.png' 
                 alt='Adverlink Logo' 
                 style='width:150px; height:auto;'>
            <p>
                Phone: +40722237373<br>
                Email: <a href='mailto:support@adverlink.net'>support@adverlink.net</a>
            </p>
        </div>
    `;

    return (
        <div className="email-templates-container p-4 bg-white rounded shadow-sm">
            <div className="mb-4">
                <label className="form-label">Email Subject</label>
                <input 
                    type="text"
                    className="form-control"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    placeholder="Enter email subject..."
                />
            </div>

            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label className="form-label">Email Content (HTML)</label>
                    {/* <button 
                        type="button" 
                        className="btn btn-xs btn-outline-primary"
                        onClick={() => setShowFooterModal(true)}
                        style={{ 
                            padding: '2px 8px',
                            fontSize: '0.75rem',
                            borderRadius: '4px'
                        }}
                    >
                        Show footer template
                    </button> */}
                </div>
                <textarea 
                    className="form-control bg-light border rounded p-3"
                    value={emailContent}
                    onChange={(e) => setEmailContent(e.target.value)}
                    rows="15"
                    placeholder="Enter your HTML email content here..."
                    style={{
                        fontFamily: 'monospace',
                        whiteSpace: 'pre',
                        overflowX: 'auto'
                    }}
                />
            </div>

            <div className="card p-3 mb-4">
                <h5>Recipients</h5>
                
                <div className="mb-3">
                    <h6>Filters</h6>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="filterPublisherDomains" className="form-label">Publisher Domains Count</label>
                            <div className="d-flex flex-column gap-2">
                            <select
                                className="form-select"
                                id="filterPublisherDomains"
                                value={filters.publisherDomains}
                                onChange={(e) => setFilters(prev => ({
                                    ...prev,
                                    publisherDomains: e.target.value
                                }))}
                            >
                                <option value="all">All</option>
                                <option value="1-5">1 - 5</option>
                                <option value="6-10">6 - 10</option>
                                <option value="11+">11+</option>
                                    <option value="custom">Custom Range</option>
                            </select>
                                
                                {filters.publisherDomains === 'custom' && (
                                    <div className="mt-2">
                                        <div className="d-flex justify-content-between mb-2">
                                            <small>{filters.customDomainsMin}</small>
                                            <small>{filters.customDomainsMax}</small>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <input
                                                type="range"
                                                className="form-range"
                                                min="0"
                                                max={getMaxDomainCount()}
                                                value={filters.customDomainsMin}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value);
                                                    const max = parseInt(filters.customDomainsMax);
                                                    if (value <= max) {
                                                        setFilters(prev => ({
                                                            ...prev,
                                                            customDomainsMin: e.target.value
                                                        }));
                                                    }
                                                }}
                                            />
                                            <input
                                                type="range"
                                                className="form-range"
                                                min="0"
                                                max={getMaxDomainCount()}
                                                value={filters.customDomainsMax}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value);
                                                    const min = parseInt(filters.customDomainsMin);
                                                    if (value >= min) {
                                                        setFilters(prev => ({
                                                            ...prev,
                                                            customDomainsMax: e.target.value
                                                        }));
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="filterSpecificDomain" className="form-label">Domain Filter</label>
                    <select 
                        className="form-select"
                                    id="filterSpecificDomain"
                                    value={filters.specificDomain}
                        onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                        specificDomain: e.target.value
                                        }));
                                    }}
                                >
                                    <option value="all">All Domains</option>
                                    {getUniqueDomains().map(domain => (
                                        <option key={domain} value={domain}>
                                            {domain}
                            </option>
                        ))}
                    </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="filterPublisherAuthority" className="form-label">Domain Authority</label>
                            <div className="d-flex flex-column gap-2">
                                <select
                                    className="form-select"
                                    id="filterPublisherAuthority"
                                    value={filters.domainAuthority}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            domainAuthority: e.target.value
                                        }));
                                    }}
                                >
                                    <option value="all">All</option>
                                    <option value="0-30">0 - 30</option>
                                    <option value="31-60">31 - 60</option>
                                    <option value="61-100">61 - 100</option>
                                    <option value="custom">Custom Range</option>
                                </select>
                                
                                {filters.domainAuthority === 'custom' && (
                                    <div className="mt-2">
                                        <div className="d-flex justify-content-between mb-2">
                                            <small>{filters.customDomainAuthorityMin}</small>
                                            <small>{filters.customDomainAuthorityMax}</small>
                                        </div>
                                        <div className="d-flex gap-2">
                                        <input
                                                type="range"
                                                className="form-range"
                                            min="0"
                                            max="100"
                                            value={filters.customDomainAuthorityMin}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value);
                                                    const max = parseInt(filters.customDomainAuthorityMax);
                                                    if (value <= max) {
                                                        setFilters(prev => ({
                                                ...prev,
                                                customDomainAuthorityMin: e.target.value
                                                        }));
                                                    }
                                                }}
                                        />
                                        <input
                                                type="range"
                                                className="form-range"
                                            min="0"
                                            max="100"
                                            value={filters.customDomainAuthorityMax}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value);
                                                    const min = parseInt(filters.customDomainAuthorityMin);
                                                    if (value >= min) {
                                                        setFilters(prev => ({
                                                ...prev,
                                                customDomainAuthorityMax: e.target.value
                                                        }));
                                                    }
                                                }}
                                        />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="filterAdvertiserSpent" className="form-label">Advertiser $ Spent</label>
                            <select 
                                className="form-select"
                                id="filterAdvertiserSpent"
                                value="all"
                                disabled={true}
                            >
                                <option value="all">All</option>
                                <option value="0-10k">$0 - $10k</option>
                                <option value="10k-50k">$10k - $50k</option>
                                <option value="50k+">$50k+</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text">
                        <Search className="text-muted" />
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search recipients or domains..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className="d-flex justify-content-end mb-3">
                    <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => handleSelectAll('publisher')}
                    >
                        Select All Publishers ({selectedRecipients.filter(r => r.userType === 'publisher').length}/{users.filter(u => u.userType === 'publisher').length})
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={() => handleSelectAll('advertiser')}
                    >
                        Select All Advertisers ({selectedRecipients.filter(r => r.userType === 'advertiser').length}/{users.filter(u => u.userType === 'advertiser').length})
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleClearAll}
                    >
                        Clear All ({selectedRecipients.length})
                    </button>
                </div>

                <div className="border rounded p-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <div className="fw-bold mb-2">Publishers</div>
                    {filteredUsers.publishers.length > 0 ? (
                        filteredUsers.publishers.map((publisher) => {
                            const publisherDomains = userDomains
                                .filter(domain => domain.userId === publisher.userId)
                                .map(domain => ({
                                    domain: domain.domain,
                                    authority: domain.domainAuthority || 0
                                }));
                                
                            return (
                            <div key={publisher.userId} className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={publisher.userId}
                                    id={`publisher-${publisher.userId}`}
                                    checked={selectedRecipients.some(r => r.userId === publisher.userId)}
                                    onChange={() => toggleRecipient(publisher)}
                                />
                                <label className="form-check-label" htmlFor={`publisher-${publisher.userId}`}>
                                    {publisher.email} ({publisher.userType})
                                        {publisherDomains.length > 0 && (
                                            <small className="text-muted ms-2">
                                            Domains: {publisherDomains.map(d => 
                                                `${d.domain} (DA: ${d.authority})`
                                            ).join(', ')}
                                            </small>
                                        )}
                                </label>
                            </div>
                            );
                        })
                    ) : (
                        <p className="text-muted">No publishers found.</p>
                    )}

                    <div className="fw-bold mb-2 mt-3">Advertisers</div>
                    {filteredUsers.advertisers.length > 0 ? (
                        filteredUsers.advertisers.map((advertiser) => (
                            <div key={advertiser.userId} className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={advertiser.userId}
                                    id={`advertiser-${advertiser.userId}`}
                                    checked={selectedRecipients.some(r => r.userId === advertiser.userId)}
                                    onChange={() => toggleRecipient(advertiser)}
                                />
                                <label className="form-check-label" htmlFor={`advertiser-${advertiser.userId}`}>
                                    {advertiser.email} ({advertiser.userType})
                                </label>
                            </div>
                        ))
                    ) : (
                        <p className="text-muted">No advertisers found.</p>
                    )}
                </div>


            </div>

            <div className="d-flex justify-content-between align-items-center">
                <Button 
                    variant="primary"
                    onClick={() => setShowConfirmModal(true)}
                    disabled={!emailContent || !emailSubject || selectedRecipients.length === 0}
                >
                    <Mail className="me-2" />
                    Send Email
                </Button>
                <Button 
                    variant="outline-secondary"
                    onClick={() => {
                        setEmailContent(defaultEmailTemplate);
                        setEmailSubject('');
                        setSelectedRecipients([]);
                    }}
                >
                    Clear Form
                </Button>
            </div>

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <strong>Subject:</strong>
                        <div className="mt-2 p-2 bg-light border rounded">
                            {emailSubject}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                        <strong>Recipients:</strong>
                            <Button 
                                variant="link" 
                                size="sm"
                                onClick={() => setShowDetailedList(true)}
                                disabled={selectedRecipients.length === 0}
                            >
                                Show Detailed List
                            </Button>
                    </div>
                        <div className="mt-2 p-2 bg-light border rounded">
                            {getRecipientText()}
                        </div>
                    </div>
                    {getActiveFiltersText().length > 0 && (
                        <div className="mb-3">
                            <strong>Active Filters:</strong>
                            <div className="mt-2 p-2 bg-light border rounded">
                                <ul className="list-unstyled mb-0">
                                    {getActiveFiltersText().map((filter, index) => (
                                        <li key={index} className="mb-1">
                                            <small className="text-muted">• {filter}</small>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className="mb-3">
                        <strong>Email Content Preview:</strong>
                        <div className="mt-2 p-2 bg-light border rounded" style={{ height: '300px', overflow: 'auto' }}>
                            <iframe
                                srcDoc={emailContent}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    backgroundColor: 'white'
                                }}
                                title="Email Preview"
                            />
                        </div>
                    </div>
                    <div className="alert alert-warning">
                        Are you sure you want to send this email?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSendEmail}>
                        Confirm Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal 
                show={showDetailedList} 
                onHide={() => setShowDetailedList(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detailed Recipients List</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <div className="mb-3">
                        <strong>Total Recipients:</strong> {selectedRecipients.length}
                        <br />
                        <strong>Publishers:</strong> {selectedRecipients.filter(r => r.userType === 'publisher').length}
                        <br />
                        <strong>Advertisers:</strong> {selectedRecipients.filter(r => r.userType === 'advertiser').length}
                    </div>
                    <div className="detailed-recipients-list">
                        {selectedRecipients.map(recipient => getDetailedRecipientInfo(recipient))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetailedList(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFooterModal} onHide={() => setShowFooterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Email Footer Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="bg-light border rounded p-3" style={{ 
                            fontFamily: 'monospace',
                            whiteSpace: 'pre-wrap',
                            maxHeight: '400px',
                            overflowY: 'auto'
                        }}>
                            {emailFooterTemplate}
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="alert alert-info">
                            <small>Copy this template and paste it at the end of your email content.</small>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFooterModal(false)}>
                        Close
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={() => {
                            navigator.clipboard.writeText(emailFooterTemplate);
                            setShowFooterModal(false);
                        }}
                    >
                        Copy to Clipboard
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmailTemplatesManager; 